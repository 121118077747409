import { Box, BoxSx, Button, Input } from "@modernatx/ui-kit-react";
import React from "react";

import { BmiCalculatorBlockProps } from "@/components/blocks/BmiCalculator";
import { BlockText } from "@/components/BlockText";
import { calculateBMI } from "@/utils/quizUtils";

interface FormData {
  weight: string;
  height: string;
}
type InputErrors = FormData;

export const BmiCalculatorForm: React.FC<{
  submitButtonText: BmiCalculatorBlockProps["buttonTexts"]["submit"];
  inputLabels: BmiCalculatorBlockProps["inputLabels"];
  inquiryTexts: BmiCalculatorBlockProps["inquiryTexts"];
  bmiCallback: (bmi: number) => void;
  sx?: BoxSx;
}> = ({ submitButtonText, inputLabels, inquiryTexts, bmiCallback, sx }) => {
  const MIN_WEIGHT = 25.4;
  const MAX_WEIGHT = 317.5;
  const MIN_HEIGHT = 139.7;
  const MAX_HEIGHT = 243.8;
  const weightErrorString = "Weight must be between {MIN_WEIGHT}kg and {MAX_WEIGHT}kg.";
  const heightErrorString = "Height must be between {MIN_HEIGHT}cm and {MAX_HEIGHT}cm.";
  const fieldRequiredString = "This field is required.";
  const [formData, setFormData] = React.useState<FormData>({ weight: "", height: "" });
  const [inputErrors, setInputErrors] = React.useState<InputErrors>({ weight: "", height: "" });

  const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setInputErrors((prev) => ({ ...prev, [name]: "" }));
  }, []);

  const getFormErrors = React.useCallback(() => {
    const { weight, height } = formData;
    const weightNum = parseFloat(weight);
    const heightNum = parseFloat(height);

    const errors = {
      weightError: !weight
        ? fieldRequiredString
        : isNaN(weightNum) || weightNum < MIN_WEIGHT || weightNum > MAX_WEIGHT
          ? weightErrorString
              .replace("{MIN_WEIGHT}", MIN_WEIGHT.toString())
              .replace("{MAX_WEIGHT}", MAX_WEIGHT.toString())
          : "",
      heightError: !height
        ? fieldRequiredString
        : isNaN(heightNum) || heightNum < MIN_HEIGHT || heightNum > MAX_HEIGHT
          ? heightErrorString
              .replace("{MIN_HEIGHT}", MIN_HEIGHT.toString())
              .replace("{MAX_HEIGHT}", MAX_HEIGHT.toString())
          : ""
    };
    return errors;
  }, [formData]);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { weightError, heightError } = getFormErrors();
      if (!weightError && !heightError) {
        const bmi = calculateBMI(parseFloat(formData.weight), parseFloat(formData.height));
        bmiCallback(bmi);
      } else {
        setInputErrors({ weight: weightError, height: heightError });
      }
    },
    [bmiCallback, formData.weight, formData.height, getFormErrors]
  );

  return (
    <Box as="form" sx={{ display: "flex", flexDirection: "column", ...sx }} onSubmit={handleSubmit}>
      <BlockText text={inquiryTexts.weight} />
      <Input
        label={inputLabels.weight}
        name="weight"
        value={formData.weight}
        error={inputErrors.weight}
        sx={{ pt: 2, pb: 5 }}
        onChange={handleInputChange}
      />
      <BlockText text={inquiryTexts.height} />
      <Input
        label={inputLabels.height}
        name="height"
        value={formData.height}
        error={inputErrors.height}
        sx={{ pt: 2, pb: 5 }}
        onChange={handleInputChange}
      />
      <Button type="submit">{submitButtonText}</Button>
    </Box>
  );
};
