import { Box, Button, Input, Notification, ThemeOverride } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { EmailSignupError, useEmailSignUp } from "@/hooks/useEmailSignup";
import { useRecaptcha } from "@/hooks/useRecaptcha";
import { SurveyBooleanQuestionProps, SurveyProps, SurveySignupFormProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const Survey: React.FC<SurveyProps> = ({ questions }) => {
  const [pageNum, setPageNum] = React.useState(0);
  const [surveyVisible, setSurveyVisible] = React.useState(false);
  const CONFIRMED_VALUE = "1";
  const surveyType = "MDRNA_US_SURVEY_COMPLETED";

  React.useEffect(() => {
    const confirmedValue = localStorage.getItem(surveyType);
    setSurveyVisible(confirmedValue !== CONFIRMED_VALUE);
  }, [surveyType]);

  const BooleanQuestion: React.FC<SurveyBooleanQuestionProps> = ({ header, footer, formId }) => {
    const router = useRouter();
    const { verifyRecaptcha } = useRecaptcha();
    const [isPFSPreferred, setIsPFSPreferred] = React.useState<boolean | null>(null);
    const [actionUrl, setActionUrl] = React.useState(router.asPath);
    const responseKey = "Is_PFS_Preferred";

    React.useEffect(() => {
      if (router) {
        const searchParams = new URLSearchParams();
        const value = isPFSPreferred ? "true" : "false";
        searchParams.set(responseKey, value);
        setActionUrl(`${router.asPath}?${searchParams.toString()}`);
      }
    }, [router, isPFSPreferred]);

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const success = await verifyRecaptcha();
        if (!success) {
          alert("reCAPTCHA validation failed");
          return;
        }
      } catch (e) {
        alert("Error submitting form. Please try again.");
      }

      setPageNum(isPFSPreferred ? 1 : 2);
    };

    return (
      <Box as="form" sx={{ margin: 8 }} id={formId} action={actionUrl} onSubmit={handleFormSubmit}>
        {header && <BlockText {...header} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "flex-start",
            alignSelf: "stretch"
          }}
        >
          <Button variant="primary" type="submit" onClick={() => setIsPFSPreferred(true)}>
            Yes
          </Button>
          <Button variant="primary" type="submit" onClick={() => setIsPFSPreferred(false)}>
            No
          </Button>
        </Box>
        {footer && <BlockText {...footer} />}
      </Box>
    );
  };

  const SignupForm: React.FC<SurveySignupFormProps> = ({
    header,
    footer,
    submitLabel,
    notification
  }) => {
    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const { error, setValue, submitEmail, value } = useEmailSignUp({
      apiEndpoint: "/api/newsletter-signup"
    });

    const { errorEmailFormatText = "", errorText = "", successText = "" } = notification || {};

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
      [setValue]
    );

    const handleSubmit = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitEmail().then(() => setNotificationOpen(true));
      },
      [submitEmail]
    );

    const handleNotificationDismiss = React.useCallback(() => {
      setNotificationOpen(false);
      if (error === null) {
        localStorage.setItem(surveyType, CONFIRMED_VALUE);
        setSurveyVisible(false);
      }
    }, [error]);

    const notificationText = React.useMemo(() => {
      if (error === EmailSignupError.InvalidEmail) {
        return errorEmailFormatText;
      } else if (error === EmailSignupError.ServerError) {
        return errorText;
      } else {
        return successText;
      }
    }, [errorEmailFormatText, errorText, error, successText]);

    return (
      <Box sx={{ margin: 8 }}>
        {header && <BlockText {...header} />}
        <Box
          as="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: 4 }}
          onSubmit={handleSubmit}
        >
          <Input label="Email (optional)" sx={{ flex: 1 }} value={value} onChange={handleChange} />
          <Button type="submit" sx={{ flexGrow: 0 }}>
            <BlockText as="span" text={submitLabel || "Submit"} color="buttonColorPrimary" />
          </Button>
        </Box>
        {footer && <BlockText {...footer} />}
        <Notification
          icon={error === null ? "check-circle" : "close-circle"}
          isOpen={notificationOpen}
          showX={error === EmailSignupError.ServerError}
          status={error === null ? "success" : "error"}
          text={notificationText as string}
          onDismiss={handleNotificationDismiss}
        />
      </Box>
    );
  };

  const renderQuestion = (
    question: SurveyBooleanQuestionProps | SurveySignupFormProps | undefined
  ) => {
    if (!question) {
      return null;
    }
    switch (question.type) {
      case "BooleanQuestion":
        return <BooleanQuestion {...question} />;
      case "SignupForm":
        return <SignupForm {...question} />;
      default:
        return null;
    }
  };

  return (
    surveyVisible && (
      <ThemeOverride
        mode="light"
        theme={{
          colorsLight: {
            accent01: "#ACDCD2",
            buttonBackgroundColorPrimary: "#FFF",
            buttonBorderColorPrimary: "#191F2A",
            buttonHoverBackgroundColorPrimary: "#FFF",
            buttonHoverBorderColorPrimary: "#0379B2",
            buttonColorPrimary: "#0379B2",
            buttonHoverColorPrimary: "#0379B2"
          }
        }}
      >
        <Box
          sx={{ backgroundColor: "accent01", borderRadius: "medium", marginBottom: [10, 10, 0] }}
        >
          {questions?.[pageNum] && renderQuestion(questions[pageNum])}
        </Box>
      </ThemeOverride>
    )
  );
};
