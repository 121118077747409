import { Box, Button, Modal } from "@modernatx/ui-kit-react";
import React from "react";

import { ExitModalBlockProps } from "@/types/Block";

import { useExitModal } from "../../hooks/useExitModal";
import { BlockText } from "../BlockText";

export const ExitModal: React.FC<ExitModalBlockProps> = ({ buttonText, text }) => {
  const { isExitModalVisible, openExitModal, exitLink } = useExitModal();

  return (
    <Modal isOpen={isExitModalVisible} onDismiss={() => openExitModal(false)}>
      <Box sx={{ py: [5, 10, 15], px: [5, 10, 20] }}>
        <BlockText {...text} />
        <Button
          // The data-exiting-link attribute is needed so we can allow this link to exit
          data-exiting-link={true}
          external={true}
          href={exitLink}
          size="small"
          target="_blank"
        >
          {typeof buttonText === "string" ? buttonText : <BlockText as="span" text={buttonText} />}
        </Button>
      </Box>
    </Modal>
  );
};
