import React from "react";

export interface Gradient {
  color: string;
  percent: number;
}

export const useCssColorGradient = (gradient?: Gradient[]): string | null => {
  return React.useMemo(() => {
    if (!gradient) {
      return null;
    }

    return `linear-gradient(0deg, ${gradient.map((entry) => `${entry.color} ${entry.percent}%`).join(", ")})`;
  }, [gradient]);
};
