import React from "react";
import reactStringReplace from "react-string-replace";

interface ReplaceTextProps {
  children?: string;
  replace: {
    [key: string]: (props: { children: string }) => React.ReactNode;
  };
}

export const ReplaceText: React.FC<ReplaceTextProps> = ({ children, replace }) => {
  if (!children) {
    return null;
  }

  let replacedText: React.ReactNode[] | string = children;

  Object.keys(replace).forEach((replaceKey, i) => {
    const regexp = new RegExp(`\\[${replaceKey}\\]([^\\[]*)\\[\\/${replaceKey}\\]`, "g");
    const replacer = replace[replaceKey];

    replacedText = reactStringReplace(replacedText, regexp, (match, j) => (
      <React.Fragment key={`${i}-${j}`}>{replacer?.({ children: match })}</React.Fragment>
    ));
  });

  return <>{replacedText}</>;
};
