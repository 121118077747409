import { Country } from "@/types/Country";
import { Language } from "@/types/Language";

export const countryCodeMap: Record<Country, Partial<Record<Language | "native", string>>> = {
  // The "native" here refers to the native name of the country in its primary language.
  at: { native: "Österreich", en: "Austria" },
  ae: { native: "الإمارات العربية المتحدة", en: "United Arab Emirates" },
  au: { native: "Australia" },
  be: { de: "Belgien", fr: "Belgique", native: "België", en: "Belgium" },
  bg: { native: "Bulgaria" },
  ca: { native: "Canada" },
  ch: { native: "Switzerland" },
  cy: { native: "Κύπρος", en: "Cyprus" },
  cz: { native: "Česká republika", en: "Czech Republic" },
  de: { native: "Deutschland", en: "Germany" },
  dk: { native: "Danmark", en: "Denmark" },
  ee: { native: "Eesti", en: "Estonia" },
  es: { native: "España", en: "Spain" },
  fi: { native: "Suomi", en: "Finland" },
  fr: { native: "France" },
  gb: { native: "Great Britain" },
  gr: { native: "Ελλάδα", en: "Greece" },
  hr: { native: "Hrvatska", en: "Croatia" },
  hu: { native: "Magyarország", en: "Hungary" },
  ie: { native: "Ireland" },
  it: { native: "Italia", en: "Italy" },
  jp: { native: "Japan" },
  kr: { native: "South Korea" },
  lt: { native: "Lietuva", en: "Lithuania" },
  lu: { de: "Luxemburg", native: "Luxembourg" },
  lv: { native: "Latvija", en: "Latvia" },
  mt: { native: "Malta" },
  nl: { native: "Nederland", en: "Netherlands" },
  pl: { native: "Polska", en: "Poland" },
  pt: { native: "Portugal" },
  qa: { native: "قطر", en: "Qatar" },
  ro: { native: "România" },
  se: { native: "Sverige", en: "Sweden" },
  sg: { native: "Singapore" },
  si: { native: "Slovenija", en: "Slovenia" },
  sk: { native: "Slovensko", en: "Slovakia" },
  us: { native: "United States" },
  no: { native: "Norge", en: "Norway" },
  is: { native: "Ísland", en: "Iceland" },
  tw: { native: "台灣", en: "Taiwan" }
};
