import { Button, Modal } from "@modernatx/ui-kit-react";
import React from "react";

import { ModalButtonProps } from "@/types/Block";

const ModalButton: React.FC<React.PropsWithChildren<ModalButtonProps>> = ({
  buttonProps,
  modalSize,
  children
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} {...buttonProps} />
      <Modal isOpen={isModalOpen} size={modalSize} onDismiss={() => setIsModalOpen(false)}>
        {children}
      </Modal>
    </>
  );
};

export default ModalButton;
