import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { Country } from "@/types/Country";

export const useAvailableCountries = () => {
  const { alternates = [], country, environment } = useExperience();

  const availableCountries = React.useMemo(() => {
    const newCountrySet = new Set<Country>();

    alternates.forEach((alt) => {
      const showAlternate = environment === "production" ? alt.isLiveInProduction : true;

      if (showAlternate && alt.country && alt.country !== country) {
        newCountrySet.add(alt.country);
      }
    });

    return newCountrySet;
  }, [alternates, country, environment]);

  return availableCountries;
};
