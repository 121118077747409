import React from "react";

import { config } from "@/config/environment";

declare const grecaptcha: {
  enterprise: {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
  };
};

export enum RecaptchaVerifySubmitError {
  RecaptchaError,
  ServerError
}

export function useRecaptcha() {
  const [error, setError] = React.useState<RecaptchaVerifySubmitError | null>(null);

  const verifyRecaptcha = React.useCallback(async () => {
    setError(null);

    try {
      const token = await grecaptcha.enterprise.execute(`${config.recaptchaSiteKey}`, {
        action: "submit"
      });

      const response = await fetch("/api/verify-recaptcha", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          recaptchaToken: token
        })
      });

      if (response.ok) {
        setError(null);
        return true;
      } else {
        setError(RecaptchaVerifySubmitError.ServerError);
        return false;
      }
    } catch (e) {
      setError(RecaptchaVerifySubmitError.RecaptchaError);
      return false;
    }
  }, []);

  return React.useMemo(
    () => ({
      error,
      verifyRecaptcha
    }),
    [error, verifyRecaptcha]
  );
}
