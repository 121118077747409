import { useRouter } from "next/router";
import React from "react";

export const useInitializeRouter = () => {
  const router = useRouter();
  const loaded = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!loaded.current) {
      router.replace(window.location.pathname + window.location.search, undefined, {
        shallow: false
      });
      loaded.current = true;
    }
  }, [router]);
};
