import { Box, Grid, GridColumn, Headline, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithIconCardsProps } from "@/types/Block";

import IconCard from "../rsv/IconCard";
import { ReplaceText } from "../rsv/ReplaceText";
import { TwoColumnText } from "../rsv/TwoColumnText";

export const TextWithIconCards: React.FC<TextWithIconCardsProps> = (props) => {
  const replace = React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      b: (boldProps) => <Box as="strong" {...boldProps} sx={{ fontWeight: "bold" }} />,
      sup: (supProps) => <sup {...supProps} />
    }),
    []
  );

  if (!props) {
    return null;
  }

  const { disclaimer, iconCards, text, title, isTextTwoColumns = true } = props;

  return (
    <Box sx={{ py: [5, 5, 10] }}>
      <Grid>
        <GridColumn>
          <Headline as="h2" level={4} sx={{ mb: 2 }} variant="bold">
            {title}
          </Headline>
          <TwoColumnText disable={!isTextTwoColumns}>
            {text?.map((textItem, i) => (
              <ReplaceText key={i} replace={replace}>
                {textItem}
              </ReplaceText>
            ))}
          </TwoColumnText>
          <ThemeOverride mode="light">
            {iconCards && (
              <Grid sx={{ mt: 5 }}>
                {iconCards.map((iconCard) => {
                  return (
                    <GridColumn
                      key={iconCard.cardTitle}
                      size={[4, 4, 3]}
                      sx={{ display: "flex", mb: 5 }}
                    >
                      <IconCard
                        icon={iconCard.icon}
                        title={iconCard.cardTitle}
                        subtitle={iconCard.subTitle}
                      />
                    </GridColumn>
                  );
                })}
              </Grid>
            )}
          </ThemeOverride>
          {disclaimer && (
            <Text size="md" sx={{ color: "text02" }}>
              {disclaimer}
            </Text>
          )}
        </GridColumn>
      </Grid>
    </Box>
  );
};
