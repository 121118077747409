import React from "react";

import { CheckboxCardProps } from "../EligibilityQuiz";

export const useCheckboxes = (checkboxItems: CheckboxCardProps["checkboxItems"]) => {
  const [checkboxStates, setCheckboxStates] = React.useState(
    Object.fromEntries(checkboxItems.map((item) => [item.label, false]))
  );
  const [numberOfCheckboxesSelected, setnumberOfCheckboxesSelected] = React.useState(0);
  const handleCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
      setnumberOfCheckboxesSelected((prev) => {
        if (event.target.checked) {
          return prev + 1;
        } else {
          return prev - 1;
        }
      });
      setCheckboxStates((prev) => ({
        ...prev,
        [label]: event.target.checked
      }));
    },
    []
  );
  return { checkboxStates, numberOfCheckboxesSelected, handleCheckboxChange };
};
