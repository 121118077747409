import { Box, Grid, GridColumn, Headline } from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithTitleCardsProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import { ReplaceText } from "../rsv/ReplaceText";
import TitleCard from "../rsv/TitleCard";
import { TwoColumnText } from "../rsv/TwoColumnText";

export const TextWithTitleCards: React.FC<TextWithTitleCardsProps> = (props) => {
  const replace = React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      b: (boxProps) => <Box as="strong" {...boxProps} sx={{ fontWeight: "bold" }} />
    }),
    []
  );

  const { title, upperText, lowerText, titleCards } = props;
  const isUpperTextOneLine = upperText?.length === 1 && (upperText?.[0]?.length || 0) < 130;
  return (
    <>
      <Grid>
        <GridColumn sx={{ py: 10 }}>
          <Headline as="h2" level={4} sx={{ mb: 2 }} variant="bold">
            {title}
          </Headline>
          {upperText && (
            <TwoColumnText disable={isUpperTextOneLine}>
              {upperText.map((text, i) => (
                <ReplaceText key={i} replace={replace}>
                  {text}
                </ReplaceText>
              ))}
            </TwoColumnText>
          )}
          <Grid sx={{ pb: 5, pt: 5 }}>
            {titleCards.map((cardTitle, index) => (
              <GridColumn key={index} size={[2, 4, 3]} sx={{ display: "flex", mb: 5 }}>
                <TitleCard>{cardTitle}</TitleCard>
              </GridColumn>
            ))}
          </Grid>

          {lowerText && <BlockText text={lowerText} />}
        </GridColumn>
      </Grid>
    </>
  );
};
