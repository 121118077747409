import { Button, Input, useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockText } from "@/components/BlockText";
import { Ages, calculateAge, dateWithSlashes, validateDate } from "@/utils/quizUtils";

import { UKQuizProps } from "../EligibilityQuiz";

export const Birthdate: React.FC<UKQuizProps["birthdateProps"]> = ({
  title,
  inputLabel,
  buttonText
}) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState<string>("");
  const [success, setSuccess] = React.useState<boolean>(false);
  const { goToSlideId } = useCarouselContext();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError("");
      setValue(dateWithSlashes(event.target.value));
      setSuccess(validateDate(event.target.value));
    },
    [setSuccess]
  );

  const handleClick = () => {
    const validDate = validateDate(value);
    if (!validDate) {
      setError("Birthday must be a valid date matching DD/MM/YYYY format");
    } else {
      const age = calculateAge(value);
      if (!age) {
        setError("Birthday must be a valid date matching DD/MM/YYYY format");
      } else if (age === Ages.Over65) {
        goToSlideId("EligibleForCovidAndFlu");
      } else if (age === Ages.Between6MonthsAnd65Years) {
        goToSlideId("BmiQuestion");
      } else {
        goToSlideId("Ineligible");
      }
    }
  };

  return (
    <>
      <BlockText weight="bold" size="lg" text={title} />
      <Input
        value={value}
        label={inputLabel}
        sx={{ borderRadius: "buttonBorderRadius" }}
        error={error}
        success={success}
        onChange={handleChange}
      />
      <Button onClick={handleClick}>
        <BlockText text={buttonText} />
      </Button>
    </>
  );
};
