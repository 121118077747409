import { Box, BoxSx, Button, Grid, GridColumn, GridSizeProp, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { BarChartProvider, useBarChart } from "@/hooks/useBarChart";
import { BarChartProps } from "@/types/Block";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";

const getBarSpacing = (numberOfBarChartSections: number): BoxSx => {
  if (numberOfBarChartSections > 5) {
    return {
      gap: [4, 8, 8],
      px: [6, 6, 5]
    };
  } else {
    return {
      gap: [12, 12, 8],
      px: [9, 9, 6]
    };
  }
};

const BarLabels = ({ barLabels }: { barLabels: BlockTextProps[] | undefined }) => {
  return !barLabels
    ? null
    : barLabels.map((barLabel, k) => (
        <Text key={k} as="label" variant={k === 0 ? "bold" : "regular"}>
          <BlockText {...barLabel} />
        </Text>
      ));
};

const ErrorBar = ({ errorBar }: { errorBar: number | undefined }) => {
  return !errorBar ? null : (
    <Box
      sx={{
        height: () => `${errorBar / 2}%`,
        display: "flex",
        flexShrink: 0,
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Box sx={{ maxWidth: 16, width: "100%", position: "relative" }}>
        <Box
          sx={{
            borderTop: "1px solid",
            borderBottom: "1px solid",
            height: () => `200%`,
            left: () => "25%",
            width: "50%",
            position: "absolute"
          }}
        >
          <Box sx={{ width: "50%", height: "100%", borderRight: "1px solid" }} />
        </Box>
      </Box>
    </Box>
  );
};

const Stacks = ({ stacks }: { stacks: number[] }) => {
  const { chartColors, stackBorderColors, stackRenderDirection, yMax = 100 } = useBarChart();
  const renderedStacks = stacks.map((percentage, k) => (
    <Box
      key={k}
      sx={{
        display: "flex",
        height: () => `${Math.min((percentage / yMax) * 100, 100)}%`,
        flexShrink: 0,
        justifyContent: "center",
        transition: "height 300ms cubic-bezier(0.4, 0.005, 0.06, 1)",
        width: "100%"
      }}
    >
      <Box
        sx={{
          backgroundColor: chartColors[k],
          border: stackBorderColors && percentage > 0 ? "0.5px solid" : "unset",
          borderColor: stackBorderColors ? stackBorderColors[k] : "unset",
          borderBottom: "unset",
          maxWidth: 16,
          width: "100%"
        }}
      />
    </Box>
  ));
  if (stackRenderDirection === "ascending") {
    return renderedStacks.reverse();
  } else {
    return renderedStacks;
  }
};

const BarTitles = ({
  bars
}: {
  bars: {
    barTitle?: string;
    barLabels?: BlockTextProps[];
    stacks: number[];
  }[];
}) => {
  const { barTitleSize } = useBarChart();
  return bars.map((bar, i) => (
    <Box
      key={i}
      sx={{
        pt: 1,
        width: "100%",
        textAlign: "center"
      }}
    >
      <BlockText key={i} size={barTitleSize} overflowWrap="normal" text={bar.barTitle} />
    </Box>
  ));
};

const BarChartSection: React.FC<{
  first: boolean;
  groupIndex: number;
  sectionIndex: number;
  showSectionTitle: boolean;
}> = ({ first, sectionIndex, groupIndex, showSectionTitle }) => {
  const {
    barChartGroups,
    barSpacing: barSpacingContext,
    dividerBetweenBarAndSectionTitles,
    groupBackgroundColors,
    height,
    numberOfChartSections,
    sectionTitles
  } = useBarChart();
  const barSpacing: BoxSx = React.useMemo(() => {
    return {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      ...getBarSpacing(numberOfChartSections),
      ...barSpacingContext
    };
  }, [barSpacingContext, numberOfChartSections]);
  const { bars } = barChartGroups[groupIndex]?.barChartSections[sectionIndex] || { bars: [] };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: () => height,
          width: "100%",
          backgroundColor: groupBackgroundColors[groupIndex]
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: "stroke01",
            borderLeft: first ? "1px solid" : "1px dotted",
            ...barSpacing
          }}
        >
          {bars.map((bar, j) => (
            <Box
              key={j}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                textAlign: "center",
                width: "100%"
              }}
            >
              <BarLabels barLabels={bar.barLabels} />
              <ErrorBar errorBar={bar.errorBar} />
              <Stacks stacks={bar.stacks} />
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={barSpacing}>
        <BarTitles bars={bars} />
      </Box>

      {showSectionTitle ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2
          }}
        >
          <Box
            sx={{
              borderTop: dividerBetweenBarAndSectionTitles ? "2px solid" : undefined,
              textAlign: "center",
              px: 1
            }}
          >
            <BlockText weight="bold" {...sectionTitles[sectionIndex]} />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const RenderGroupTitles = () => {
  const { barChartGroups, groupBackgroundColors } = useBarChart();
  if (!barChartGroups[0]?.groupTitle) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        display: "flex",
        top: 5
      }}
    >
      {barChartGroups.map((barChartGroup, i) => {
        return (
          <Box
            key={i}
            sx={{
              width: "50%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              borderLeft: i === 0 ? "1px solid" : "1px dotted"
            }}
          >
            <Box
              sx={{
                backgroundColor: groupBackgroundColors ? groupBackgroundColors[i] : null,
                width: "auto",
                p: 3
              }}
            >
              <BlockText text={barChartGroup.groupTitle} size="xl" />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const HeaderLabels = ({
  chartAreaWidth,
  selection
}: {
  chartAreaWidth: number;
  selection: number;
}) => {
  const { headerLabels } = useBarChart();
  return headerLabels ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pb: headerLabels.pb
      }}
    >
      <BlockText text={headerLabels?.yAxisHeaderLabel} />
      <Box sx={{ display: "flex", justifyContent: "space-between", width: () => chartAreaWidth }}>
        {headerLabels?.sectionHeaderLabels.map((sectionHeaderLabel, i) => (
          <Box
            key={i}
            sx={{
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
              display: [selection === i ? "flex" : "none", null, "flex"]
            }}
          >
            <Box sx={{ px: 2, borderBottom: "2px solid" }}>
              <BlockText size="xl" weight="bold" text={sectionHeaderLabel} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
};

const YAxis = () => {
  const { height, labelYAxis, intervals, yMax = 100 } = useBarChart();
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingInlineStart: 12,
        position: "relative",
        height: () => height
      }}
    >
      <Text
        as="h4"
        size="2xl"
        variant="bold"
        sx={{
          left: 0,
          position: "absolute",
          top: "50%",
          transform: "rotate(-90deg) translateX(-50%)",
          transformOrigin: "0 0",
          whiteSpace: "nowrap"
        }}
      >
        <BlockText size="2xl" text={labelYAxis} />
      </Text>
      {/* intervals can either be a list of BlockTexts, or a number. 
      If it is a list, render each BlockText.
      If it is a number, auto calculate and render that amount of y labels */}
      {typeof intervals === "number"
        ? // from 0 to yMax, create an array of length intervals, and map over it
          Array.from({ length: intervals }, (_, i) => (
            <Text key={i} sx={{ paddingInlineEnd: 1 }}>
              {Math.round((yMax / (intervals - 1)) * -i + yMax)}%
            </Text>
          ))
        : intervals.map((interval, i) => (
            <Box
              key={i}
              sx={{
                paddingInlineEnd: 1,
                textAlign: "end"
              }}
            >
              <BlockText text={interval.text} />
            </Box>
          ))}
    </Box>
  );
};

const Legend = () => {
  const { legendLabels, mobileKeyFlexDirection, chartColors, stackBorderColors } = useBarChart();
  return legendLabels ? (
    <Box
      sx={{
        borderColor: "stroke02",
        borderWidth: "1px",
        borderStyle: ["unset", null, "solid"],
        display: "flex",
        justifyContent: "center",
        flexDirection: [mobileKeyFlexDirection || "row", "row", "row"],
        flexWrap: "wrap",
        gap: 5,
        p: [0, 0, 5]
      }}
    >
      {legendLabels.map((label, i) => (
        <Box key={i} sx={{ display: "flex", gap: 2 }}>
          <Box
            sx={{
              backgroundColor: chartColors[i],
              borderColor: stackBorderColors ? stackBorderColors[i] : "unset",
              borderStyle: stackBorderColors ? "solid" : "unset",
              borderWidth: stackBorderColors ? "1px" : "unset",
              height: 6,
              width: 6
            }}
          />
          <Text>{label}</Text>
        </Box>
      ))}
    </Box>
  ) : null;
};

const ChartFooter = () => {
  const { footerText } = useBarChart();
  return (
    footerText && (
      <Box sx={{ textAlign: "center" }}>
        <BlockText text={[footerText]} />
      </Box>
    )
  );
};

const LabelXAxis = () => {
  const { labelXAxis } = useBarChart();
  return (
    labelXAxis && (
      <Box sx={{ textAlign: "center" }}>
        <BlockText {...labelXAxis} />
      </Box>
    )
  );
};

const RenderBarChart = () => {
  const {
    barChartGroups,
    collapseOnMobile,
    displaySectionTitleOnMobile = false,
    labelSelect,
    sectionTitles,
    legendLabels
  } = useBarChart();
  const [selection, setSelection] = React.useState<number>(0);
  const chartRef = React.useRef<HTMLElement | null>(null);
  const [chartAreaWidth, setChartAreaWidth] = React.useState(0);

  const updateChartAreaWidth = () => {
    if (chartRef.current) {
      setChartAreaWidth(chartRef.current.offsetWidth);
    }
  };

  React.useEffect(() => {
    updateChartAreaWidth();
    window.addEventListener("resize", updateChartAreaWidth);
    return () => {
      window.removeEventListener("resize", updateChartAreaWidth);
    };
  }, []);

  const gridSize: GridSizeProp = [4, 8, 12];
  const pushSize: GridSizeProp = [0, 0, 0];
  return (
    <Grid>
      <GridColumn size={gridSize} push={pushSize}>
        {/* mobile selector */}
        {!!sectionTitles?.length && collapseOnMobile && (
          <Box
            sx={{
              alignItems: "center",
              display: ["flex", null, "none"],
              flexDirection: "column"
            }}
          >
            <Text as="h4" variant="bold">
              {labelSelect}
            </Text>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
                pb: 5,
                pt: 3,
                gap: 1
              }}
            >
              {sectionTitles?.map((sectionTitle, i) => (
                <Button
                  key={i}
                  size="small"
                  variant={i === selection ? "primary" : "secondary"}
                  onClick={() => setSelection(i)}
                >
                  <BlockText
                    color={i === selection ? "cta01Text" : "text02"}
                    {...sectionTitle}
                    size="md"
                  />
                </Button>
              ))}
            </Box>
          </Box>
        )}
        <HeaderLabels chartAreaWidth={chartAreaWidth} selection={selection} />
        {/* chart container */}
        <Box sx={{ display: "flex" }}>
          <YAxis />
          <Box ref={chartRef} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* collapsed mobile view (showing 1 chart section at a time)*/}
            {collapseOnMobile && (
              <Box
                sx={{
                  position: "relative",
                  display: ["flex", null, "none"],
                  width: "100%"
                }}
              >
                <RenderGroupTitles />
                {barChartGroups.map((barChartGroup, groupIndex) => (
                  <BarChartSection
                    key={groupIndex}
                    first={groupIndex === 0}
                    groupIndex={groupIndex}
                    sectionIndex={selection}
                    showSectionTitle={displaySectionTitleOnMobile}
                  />
                ))}
              </Box>
            )}

            {/* Desktop view */}
            <Box
              sx={{
                display: [collapseOnMobile ? "none" : "flex", null, "flex"],
                width: "100%",
                position: "relative"
              }}
            >
              <RenderGroupTitles />
              {barChartGroups.map((barChartGroup, groupIndex) => (
                <Box
                  key={groupIndex}
                  sx={{
                    display: "flex",
                    width: "100%"
                  }}
                >
                  {barChartGroup.barChartSections.map((chartSectionProps, sectionIndex) => (
                    <BarChartSection
                      key={sectionIndex}
                      groupIndex={groupIndex}
                      sectionIndex={sectionIndex}
                      first={sectionIndex === 0}
                      showSectionTitle={true}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: 5,
            gap: 3
          }}
        >
          <LabelXAxis />
          {!!legendLabels?.length && <Legend />}
          <ChartFooter />
        </Box>
      </GridColumn>
    </Grid>
  );
};

export const BarChart: React.FC<BarChartProps> = (props) => {
  return (
    <BarChartProvider chartProps={props}>
      <RenderBarChart />
    </BarChartProvider>
  );
};
