import { Box, Grid, GridColumn, ShareMenu } from "@modernatx/ui-kit-react";
import React from "react";

import { useSocialShareFunctions } from "@/hooks/useSocialShareFunctions";
import { BannerBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const Banner: React.FC<BannerBlockProps> = ({ icon, text, shareMenuProps }) => {
  const { shareToFacebook, shareToLinkedin, shareToX } = useSocialShareFunctions({
    facebookUrl: shareMenuProps?.facebookUrl,
    xShareProps: shareMenuProps?.xShareProps,
    linkedinUrl: shareMenuProps?.linkedinUrl
  });
  return (
    <>
      <Grid>
        <GridColumn size={[4, 8, 10]} push={[0, 0, 1]}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              position: "relative"
            }}
          >
            {icon && (
              <Box
                as="img"
                role="presentation"
                src={icon}
                sx={{
                  alignItems: "center",
                  bg: "background02",
                  borderRadius: "50%",
                  display: "flex",
                  height: 50,
                  justifyContent: "center",
                  position: "relative",
                  width: 50,
                  mb: 5
                }}
              />
            )}
            <BlockText text={text} />
            {shareMenuProps && (
              // add share component here once completed
              <Box sx={{ mt: 10 }}>
                <ShareMenu
                  buttonLabel={shareMenuProps.buttonLabel}
                  buttonVariant={shareMenuProps.buttonVariant}
                  copyLinkUrl={shareMenuProps.copyLinkUrl}
                  instagramUrl={shareMenuProps.instagramUrl}
                  facebookClickHandler={shareToFacebook}
                  xClickHandler={shareToX}
                  linkedinClickHandler={shareToLinkedin}
                />
              </Box>
            )}
          </Box>
        </GridColumn>
      </Grid>
    </>
  );
};
