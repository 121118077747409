import React from "react";

import { BlockProps } from "@/types/Block";

const getImages = (props: BlockProps["props"], images: string[] = []) => {
  if (Array.isArray(props)) {
    props.forEach((prop) => getImages(prop, images));
  } else if (typeof props === "object" && props !== null) {
    if ("as" in props && props.as === "img" && "src" in props) {
      images.push(props.src);
    } else if ("backgroundImage" in props && (props.backgroundImage as string).startsWith("url(")) {
      images.push(getBackgroundUrl(props.backgroundImage as string));
    }
    Object.values(props).forEach((prop) => getImages(prop, images));
  }

  return images;
};

const loadImage = (src: string, callback: () => void) => {
  const img = new Image();
  const div = document.createElement("div");
  div.style.height = "0";
  div.style.width = "0";
  div.style.overflow = "hidden";
  div.setAttribute("aria-hidden", "true");
  const removeElement = () => {
    // Android Chrome is occasionally saying the element
    // doesn't exist to remove, so we check if it has the
    // body as a parent before trying to remove it
    if (div.parentElement === document.body) {
      document.body.removeChild(div);
    }
  };
  const timeout = setTimeout(() => {
    removeElement();
    callback();
  }, 2000);
  img.src = src;
  img.onload = () => {
    clearTimeout(timeout);
    removeElement();
    callback();
  };
  img.onerror = () => {
    clearTimeout(timeout);
    removeElement();
    callback();
  };
  div.append(img);
  document.body.append(div);
};

export const imageLoadMap: { current: Record<string, boolean> } = { current: {} };

const getBackgroundUrl = (backgroundUrl: string): string =>
  backgroundUrl.replace(/^url\(/, "").replace(`\)`, "");

export const useIsLoadingImages = (props: BlockProps) => {
  const backgroundImage = props.block?.backgroundImage;
  const images = React.useMemo(() => {
    let imagesCollection: string[] = [];

    if (backgroundImage) {
      if (Array.isArray(backgroundImage)) {
        imagesCollection = imagesCollection.concat(
          backgroundImage
            .filter((b) => typeof b === "string")
            .map((b) => getBackgroundUrl(b as string))
        );
      } else if (typeof backgroundImage === "string") {
        imagesCollection = imagesCollection.concat([getBackgroundUrl(backgroundImage)]);
      }
    }

    return props ? imagesCollection.concat(getImages(props.props)) : imagesCollection;
  }, [backgroundImage, props]);
  const [loading, loadingSet] = React.useState(!!images.length);

  React.useEffect(() => {
    let loadedCount = 0;

    images.forEach((src) => {
      if (imageLoadMap.current[src]) {
        loadedCount = loadedCount + 1;
      } else {
        loadImage(src, () => {
          imageLoadMap.current[src] = true;
          loadedCount = loadedCount + 1;

          if (loadedCount === images.length) {
            loadingSet(false);
          }
        });
      }

      loadingSet(loadedCount !== images.length);
    });
  }, [images]);

  return loading;
};
