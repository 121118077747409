import { Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { TitleCardProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import TitleCard from "../rsv/TitleCard";

export const TitleCards: React.FC<TitleCardProps> = ({ titleCards, variant }) => {
  return (
    <Grid>
      <GridColumn>
        {titleCards && (
          <Grid>
            {titleCards.map(({ icon, iconSize, backgroundColor, title }, index) => (
              <GridColumn
                key={index}
                size={[4, 4, 4]}
                sx={{
                  display: "flex",
                  mb: [index === titleCards.length - 1 ? 0 : 5, null, 0]
                }}
              >
                <TitleCard
                  icon={icon}
                  iconSize={iconSize}
                  variant={variant}
                  backgroundColor={backgroundColor}
                >
                  <BlockText {...title} />
                </TitleCard>
              </GridColumn>
            ))}
          </Grid>
        )}
      </GridColumn>
    </Grid>
  );
};
