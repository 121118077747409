import { BoxSx, FlagIconName, FlagIconNameType } from "@modernatx/ui-kit-react";

export const countryLanguageModalTargetStyles: BoxSx = {
  display: "flex",
  alignItems: "center",
  textDecoration: "underline",
  textDecorationColor: "transparent",
  borderColor: "transparent",
  borderRadius: "buttonBorderRadius",
  borderWidth: 1,
  borderStyle: "solid",
  transition: "colorMedium",
  ":hover": {
    textDecorationColor: "inherit"
  },
  ":active": {
    textDecorationColor: "inherit"
  },
  ":focus-visible": {
    borderColor: "buttonFocusBorderColorTertiary"
  }
};

export const isValidIconCode = (code: string): code is FlagIconNameType => {
  return code.toUpperCase() in FlagIconName;
};

export const getFlagIcon = (countryCode: string): FlagIconNameType | undefined => {
  const upperCaseCode = countryCode.toUpperCase() as FlagIconNameType;
  if (isValidIconCode(upperCaseCode)) {
    return upperCaseCode;
  }
  return undefined;
};
