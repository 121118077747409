import { Box, Button, Checkbox, CheckboxProps, Grid, GridColumn } from "@modernatx/ui-kit-react";
import confetti from "canvas-confetti";
import { useRouter } from "next/router";
import React from "react";

import { useRecaptcha } from "@/hooks/useRecaptcha";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";

type CheckboxFormItemProps = Omit<CheckboxProps, "name" | "label"> & {
  label: string;
  labelDisplay?: BlockTextProps["text"];
};

export interface CheckboxFormBlockProps {
  formId: string;
  text: BlockTextProps[];
  checkboxItems: CheckboxFormItemProps[];
  submitButtonText: string;
  onSubmitText: BlockTextProps["text"];
}

export const CheckboxForm: React.FC<CheckboxFormBlockProps> = ({
  formId,
  text,
  checkboxItems,
  submitButtonText,
  onSubmitText
}) => {
  const [checkboxStates, setCheckboxStates] = React.useState(
    Object.fromEntries(checkboxItems.map((item) => [item.label, false]))
  );
  const [submitted, setSubmitted] = React.useState(false);

  const { verifyRecaptcha } = useRecaptcha();

  const router = useRouter();
  const [actionUrl, setActionUrl] = React.useState(router.asPath);

  React.useEffect(() => {
    if (router) {
      const searchParams = new URLSearchParams();
      Object.entries(checkboxStates).forEach(([key, isChecked]) => {
        searchParams.set(key, isChecked.toString());
      });

      setActionUrl(`${router.asPath}?${searchParams.toString()}`);
    }
  }, [checkboxStates, router]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
      setCheckboxStates((prev) => ({
        ...prev,
        [label]: event.target.checked
      }));
    },
    []
  );

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const success = await verifyRecaptcha();
      if (!success) {
        alert("reCAPTCHA validation failed");
        return;
      }
      setSubmitted(true);
      confetti({
        particleCount: 100,
        spread: 200,
        origin: { y: 0.5 }
      });
    } catch (e) {
      alert("Error submitting form. Please try again.");
    }
  };

  if (submitted) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 100
        }}
      >
        <BlockText text={onSubmitText} />
      </Box>
    );
  }

  return (
    <Box
      as="form"
      id={formId}
      action={actionUrl}
      sx={{ minHeight: 100 }}
      onSubmit={handleFormSubmit}
    >
      <Grid>
        <GridColumn size={[4, 8, 8]} push={[0, 0, 2]}>
          <BlockText text={text} />
        </GridColumn>
        <GridColumn size={[4, 8, 8]} push={[0, 0, 2]} sx={{ py: 5 }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 5 }}>
            {checkboxItems.map((item) => {
              return (
                <Checkbox
                  key={item.label}
                  label={<BlockText as="div" text={item.labelDisplay || item.label} />}
                  value={checkboxStates[item.label]}
                  onChange={(event) => handleChange(event, item.label)}
                />
              );
            })}
          </Box>
        </GridColumn>
        <GridColumn size={[4, 8, 6]} push={[0, 0, 3]}>
          <Button type="submit">{submitButtonText}</Button>
        </GridColumn>
      </Grid>
    </Box>
  );
};
