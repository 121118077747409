type DateFormat = "DD/MM/YYYY";

const dateTypeToRegexMap: Record<DateFormat, RegExp> = {
  "DD/MM/YYYY": /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
};

const getDateFromString = (dateString: string, dateFormat: DateFormat = "DD/MM/YYYY") => {
  const validDateRegex = dateTypeToRegexMap[dateFormat];
  const [day, month, year] = dateString.split("/").map(Number);

  // Checking for inproper format
  if (!validDateRegex.test(dateString) || !day || !month || !year) {
    return false;
  }
  // Additional logic to check for leap years
  if (month === 2 && day === 29) {
    if (!(year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))) {
      return false; // Not a leap year, 29th February is invalid
    }
  }
  // Create a JavaScript Date object from the input date
  const dateObject = new Date(year, month - 1, day); // month is zero-indexed in JS Dates
  return dateObject;
};

export const validateDate = (inputDate: string) => {
  const date = getDateFromString(inputDate);
  if (!date) {
    return false;
  }
  // Get the current date (without time)
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Check if the input date is in the future
  if (date > today) {
    return false;
  }

  return true;
};

export enum Ages {
  Over65 = "over 65",
  Between6MonthsAnd65Years = "between 6 months and 65 years",
  Under6Months = "under 6 months"
}
export const calculateAge = (inputDate: string) => {
  const birthdate = getDateFromString(inputDate);
  if (!birthdate) {
    return false;
  }
  const today = new Date();
  let ageYears = today.getFullYear() - birthdate.getFullYear();
  let ageMonths = today.getMonth() - birthdate.getMonth();

  // Adjust if the birth month/day is later in the year than today
  if (ageMonths < 0 || (ageMonths === 0 && today.getDate() < birthdate.getDate())) {
    ageYears = ageYears - 1;
    ageMonths += ageMonths < 0 ? 12 : 0;
  }

  const totalMonths = ageYears * 12 + ageMonths;

  if (ageYears > 65) {
    return Ages.Over65;
  } else if (totalMonths < 6) {
    return Ages.Under6Months;
  } else {
    return Ages.Between6MonthsAnd65Years;
  }
};

export const calculateBMI = (weight: number, height: number) => {
  if (weight <= 0 || height <= 0) {
    return NaN;
  }
  const heightInMeters = height / 100;
  const bmi = weight / (heightInMeters * heightInMeters);
  return bmi;
};

export const dateWithSlashes = (date: string) => {
  if (
    date.length > 0 &&
    date.charAt(date.length - 2) === "/" &&
    date.charAt(date.length - 1) === "/"
  ) {
    return date.slice(0, -1);
  }
  if (date.indexOf("/") === -1 && date.length > 2) {
    return date.slice(0, 2) + "/" + date.slice(2);
  }
  if (date.indexOf("/", 3) === -1 && date.length > 5) {
    return date.slice(0, 5) + "/" + date.slice(5);
  }
  if (date.length > 10) {
    return date.slice(0, -1);
  }
  return date;
};
