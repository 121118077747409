import { Button, ButtonProps, useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockText } from "@/components/BlockText";
import { BlockTextProps } from "@/types/BlockText";

import { useEligibility } from "../../hooks/useEligibility";
import { FlexColWithGap } from "./FlexColWithGap";

export interface MultipleChoiceProps {
  text: Exclude<BlockTextProps["text"], undefined>;
  multipleChoiceButtons: {
    buttonProps: ButtonProps;
    goToSlideId?: string;
    resetQuiz?: boolean;
  }[];
}

export const MultipleChoice: React.FC<MultipleChoiceProps> = ({ text, multipleChoiceButtons }) => {
  const { goToSlideId, goToSlide } = useCarouselContext();
  const { resetQuiz } = useEligibility();

  return (
    <>
      <BlockText text={text} />
      <FlexColWithGap>
        {multipleChoiceButtons.map((multipleChoiceButton, i) => {
          const handleClick = () => {
            if (multipleChoiceButton.resetQuiz) {
              resetQuiz();
              goToSlide(0);
            } else if (multipleChoiceButton.goToSlideId) {
              goToSlideId(multipleChoiceButton.goToSlideId);
            }
          };
          return <Button key={i} {...multipleChoiceButton.buttonProps} onClick={handleClick} />;
        })}
      </FlexColWithGap>
    </>
  );
};
