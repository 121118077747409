import { Box, BoxSx } from "@modernatx/ui-kit-react";

interface TitleCardProps {
  children: React.ReactNode;
  icon?: string;
  iconSize?: "md" | "lg";
  backgroundColor?: BoxSx["backgroundColor"];
  variant?: "shadow" | "border";
}

const TitleCard: React.FC<TitleCardProps> = ({
  children,
  icon,
  iconSize = "md",
  backgroundColor,
  variant = "shadow"
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        bg: "background01",
        border: variant === "border" ? "1px solid" : "none",
        borderColor: "stroke02",
        borderRadius: "medium",
        boxShadow: variant === "shadow" ? "boxShadow02" : "unset",
        color: "stroke01",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        fontWeight: "medium",
        justifyContent: icon ? "start" : "center",
        minHeight: 35,
        pt: backgroundColor ? 0 : 5,
        px: backgroundColor ? 0 : 5,
        pb: 5,
        textAlign: "center"
      }}
    >
      {icon && (
        <Box
          as="img"
          role="presentation"
          src={icon}
          sx={{
            backgroundColor: backgroundColor || "inherit",
            height: iconSize === "md" ? 13 : backgroundColor ? 35 : 20,
            width: "100%",
            p: backgroundColor ? 5 : 0,
            mb: 3,
            // @ts-ignore
            "> *": {
              height: "100%"
            }
          }}
        />
      )}
      <Box sx={{ px: 4 }}>{children}</Box>
    </Box>
  );
};

export default TitleCard;
