import React from "react";

import { BarChartProps } from "@/types/Block";

export interface BarChartContextProps extends BarChartProps {
  numberOfChartSections: number;
  height?: string;
}

export const BarChartContext = React.createContext<BarChartContextProps>({
  barChartGroups: [{ barChartSections: [{ bars: [{ stacks: [0] }] }] }],
  barTitleSize: "sm",
  chartColors: ["accent01", "accent02"],
  collapseOnMobile: true,
  dividerBetweenBarAndSectionTitles: false,
  displaySectionTitleOnMobile: false,
  groupBackgroundColors: ["background01"],
  stackRenderDirection: "descending",
  numberOfChartSections: 0,
  height: "400px",
  intervals: 5,
  labelYAxis: "",
  legendLabels: [],
  labelSelect: "",
  sectionTitles: [],
  yMax: 100
});

export const BarChartProvider: React.FC<{
  children: React.ReactNode;
  chartProps: BarChartProps;
}> = ({ children, chartProps }) => {
  const numberOfChartSections = React.useMemo<number>(() => {
    let count = 0;
    chartProps.barChartGroups.forEach((group) => (count += group.barChartSections.length));
    return count;
  }, [chartProps.barChartGroups]);
  const value = {
    ...chartProps,
    numberOfChartSections,
    height: "400px"
  };
  return <BarChartContext.Provider value={value}>{children}</BarChartContext.Provider>;
};
export const useBarChart = () => React.useContext(BarChartContext);
