import { Box, BoxSx, FlagIcon, Target } from "@modernatx/ui-kit-react";
import React from "react";

import { SplitButtonBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export interface ButtonClickHandlers {
  onStartButtonClick?: () => void;
  onEndButtonClick?: () => void;
}

export const SplitButton: React.FC<SplitButtonBlockProps & ButtonClickHandlers> = ({
  startIcon,
  startText,
  endText,
  onStartButtonClick,
  onEndButtonClick
}) => {
  const targetStyles: BoxSx = {
    display: "flex",
    alignItems: "center",
    textDecoration: "underline",
    textDecorationColor: "transparent",
    borderColor: "transparent",
    borderRadius: "buttonBorderRadius",
    borderWidth: 1,
    borderStyle: "solid",
    transition: "colorMedium",
    ":hover": {
      textDecorationColor: "inherit"
    },
    ":active": {
      textDecorationColor: "inherit"
    },
    ":focus-visible": {
      borderColor: "buttonFocusBorderColorTertiary"
    }
  };
  const StartTargetElement = onStartButtonClick ? Target : Box;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <StartTargetElement sx={{ gap: 2, ...targetStyles }} onClick={onStartButtonClick}>
        {startIcon && <FlagIcon icon={startIcon} size="sm" sx={{ mt: -1, mb: -1 }} />}
        {startText && <BlockText as="div" size="sm" text={startText} />}
      </StartTargetElement>
      {endText && startText && (
        <Box
          sx={{
            borderColor: "text01",
            borderStyle: "solid",
            height: 4,
            borderWidth: "0 0 0 1px"
          }}
        />
      )}
      {endText && (
        <Target sx={{ ...targetStyles }} onClick={onEndButtonClick}>
          <BlockText as="div" size="sm" text={endText} />
        </Target>
      )}
    </Box>
  );
};
