import { Country } from "@/types/Country";

// See here for supported units: https://v8.dev/features/intl-numberformat#units
interface Units {
  distance: "mile" | "kilometer";
  temperature: "fahrenheit" | "celsius";
}

const UNITS: Partial<Record<Country, Units>> = {
  us: {
    distance: "mile",
    temperature: "fahrenheit"
  },
  gb: {
    distance: "mile",
    temperature: "celsius"
  }
};

const DEFAULT_UNITS: Units = {
  distance: "kilometer",
  temperature: "celsius"
};

export const getUnitsForCountry = (countryCode: Country): Units =>
  UNITS[countryCode] || DEFAULT_UNITS;

const metersToCountryDistance = (meters: number, countryCode: Country): number => {
  const units = getUnitsForCountry(countryCode);
  const km = meters / 1000;

  return units.distance === "mile" ? km / 1.609344 : km;
};

export const metersToLocaleString = (
  meters: number,
  countryCode: Country,
  locale: string
): string => {
  const { distance: distanceUnit } = getUnitsForCountry(countryCode);
  const distanceInCountryUnits = metersToCountryDistance(meters, countryCode);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "unit",
    unit: distanceUnit,
    unitDisplay: "long"
  }).format(distanceInCountryUnits);
};
