import { Box, Grid, GridColumn, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { useSmoothSvgPath } from "@/hooks/useSmoothSvgPath";
import { TimeSeriesChartProps } from "@/types/Block";

import { BlockText } from "../BlockText";

interface TimeSeriesChartContextValue extends TimeSeriesChartProps {
  dateEnd: number;
  dateStart: number;
  height: number;
  months: { month: number; percent: number }[];
  smoothing: number;
  width: number;
}

const TimeSeriesChartContext = React.createContext<TimeSeriesChartContextValue>({
  dateEnd: Date.now(),
  dateStart: Date.now(),
  height: 400,
  intervals: [],
  months: [],
  smoothing: 0,
  timeSeries: [],
  width: 0
});

const useTimeSeriesChart = () => React.useContext(TimeSeriesChartContext);

const getRoundedNumber = (num: number) => Math.ceil(num * 100) / 100;

const getMonthPercents = (dateStart: number, dateEnd: number) => {
  const months = [];
  const start = new Date(dateStart);
  const end = new Date(dateEnd);
  const endMonth = end.getUTCMonth();
  const endYear = end.getUTCFullYear();
  let current = start;
  let i = 0;
  let days = 0;

  while (current <= end) {
    const month = current.getUTCMonth();
    const year = current.getUTCFullYear();
    const daysInMonth = new Date(year, month, 0).getUTCDate();
    let daysTotal = daysInMonth;

    // The first month
    if (i === 0) {
      daysTotal = daysInMonth - start.getUTCDate();
    }

    // The last month
    if (month === endMonth && year === endYear) {
      daysTotal = end.getUTCDate();
    }

    days = days + daysTotal;
    months.push({ daysTotal, month, year });

    i = i + 1;
    const monthNext = month + 1;
    current = new Date(current.setUTCMonth(monthNext));
  }

  return months.map((month) => ({
    ...month,
    percent: month.daysTotal / days
  }));
};

const LabelXAxis = () => {
  const { labelXAxis } = useTimeSeriesChart();

  return (
    labelXAxis && (
      <Text as="h4" size="2xl" variant="bold" sx={{ textAlign: "center" }}>
        <BlockText {...labelXAxis} />
      </Text>
    )
  );
};

const Legend = () => {
  const { timeSeries } = useTimeSeriesChart();

  return (
    <Box
      sx={{
        borderColor: "stroke02",
        borderStyle: "solid",
        borderWidth: "1px",
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        justifyContent: "center",
        p: 5
      }}
    >
      {timeSeries.map((timeseries, i) => (
        <Box key={i} sx={{ display: "flex", gap: 2 }}>
          <Box
            sx={{
              backgroundColor: () => timeseries.color,
              borderColor: "stroke01",
              borderStyle: "solid",
              borderWidth: "1px",
              height: 6,
              width: 6
            }}
          />
          <BlockText {...timeseries.title} />
        </Box>
      ))}
    </Box>
  );
};

const YAxis = () => {
  const { height, labelYAxis, intervals } = useTimeSeriesChart();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        height: () => height,
        justifyContent: "space-between",
        paddingInlineStart: 12,
        position: "relative"
      }}
    >
      <Text
        as="h4"
        size="2xl"
        variant="bold"
        sx={{
          left: 0,
          position: "absolute",
          top: "50%",
          transform: "rotate(-90deg) translateX(-50%)",
          transformOrigin: "0 0",
          whiteSpace: "nowrap"
        }}
      >
        <BlockText {...labelYAxis} />
      </Text>
      {intervals.map((interval, i) => (
        <Box
          key={i}
          sx={{
            flexShrink: 0,
            paddingInlineEnd: 1,
            textAlign: "end"
          }}
        >
          <BlockText text={interval.text} />
        </Box>
      ))}
    </Box>
  );
};

const TimeSeriesLine: React.FC<TimeSeriesChartProps["timeSeries"][0]> = ({ color, series }) => {
  const { dateEnd, dateStart, height, smoothing, width } = useTimeSeriesChart();
  const dateChange = dateEnd - dateStart;
  const d = React.useMemo(() => {
    return series.reduce((str, entry, i) => {
      const datePosition = ((entry[0] - (dateStart || 0)) / dateChange) * 100;
      const datePositionWithWidth = getRoundedNumber((datePosition / 100) * width);
      const ratePosition = getRoundedNumber(height - (entry[1] / 100) * height);
      return `${str} ${i === 0 ? "M" : "L"} ${datePositionWithWidth},${ratePosition}`;
    }, "");
  }, [dateChange, dateStart, height, series, width]);
  const dSmoothed = useSmoothSvgPath(d, { smoothing });

  return <path d={dSmoothed} fill="none" stroke={color} strokeWidth={4} strokeLinecap="round" />;
};

const TimeSeriesLines: React.FC = () => {
  const { height, months, timeSeries, width } = useTimeSeriesChart();

  return (
    <Box
      sx={{
        display: "flex",
        height: () => height,
        overflow: "hidden",
        position: "relative",
        width: "100%"
      }}
    >
      <Box
        as="svg"
        preserveAspectRatio="none"
        sx={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%"
        }}
      >
        {!!width && timeSeries?.map((series, i) => <TimeSeriesLine {...series} key={i} />)}
      </Box>
      {months.map((month, i) => (
        <Box
          key={i}
          sx={{
            borderLeft: i === 0 ? "1px solid" : null,
            borderLeftColor: i === 0 ? "stroke01" : null,
            borderBottom: "1px solid",
            borderBottomColor: "stroke01",
            borderRight: "1px solid",
            borderRightColor: "stroke02",
            width: () => `${getRoundedNumber(month.percent * 100)}%`
          }}
        />
      ))}
    </Box>
  );
};

const TimeSeriesLabels: React.FC = () => {
  const { months } = useTimeSeriesChart();

  const renderMonth = React.useCallback((month: TimeSeriesChartContextValue["months"][0]) => {
    switch (month.month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
    }
  }, []);

  return (
    <Box sx={{ display: "flex", textAlign: "center", mb: 2, mt: 2 }}>
      {months.map((month, i) => (
        <Box
          key={i}
          sx={{
            width: () => `${getRoundedNumber(month.percent * 100)}%`
          }}
        >
          <Box as="span" sx={{ display: ["none", null, month.percent > 0.05 ? "unset" : "none"] }}>
            {renderMonth(month)}
          </Box>
          <Box as="span" sx={{ display: ["unset", null, "none"] }}>
            {renderMonth(month)?.slice(0, 1)}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const TimeSeriesChart: React.FC<TimeSeriesChartProps> = (props) => {
  const chartViewport = React.useRef<HTMLElement>(null);
  const resizing = React.useRef(false);
  const context = useTimeSeriesChart();
  const dateEnd = React.useMemo(() => {
    if (props.dateEnd) {
      return props.dateEnd;
    }

    const firstSeries = props.timeSeries[0];
    const derived = firstSeries?.series[firstSeries?.series.length - 1]?.[0];

    return derived || context.dateEnd;
  }, [context.dateEnd, props.dateEnd, props.timeSeries]);
  const dateStart = React.useMemo(() => {
    if (props.dateStart) {
      return props.dateStart;
    }

    const derived = props.timeSeries[0]?.series[0]?.[0];
    return derived || context.dateStart;
  }, [context.dateStart, props.dateStart, props.timeSeries]);
  const months = React.useMemo(() => getMonthPercents(dateStart, dateEnd), [dateStart, dateEnd]);
  const [width, widthSet] = React.useState<number>(0);

  React.useEffect(() => {
    const chartViewportElement = chartViewport.current;

    if (chartViewportElement) {
      const handleResize = () => {
        if (!resizing.current) {
          resizing.current = true;
          requestAnimationFrame(() => {
            resizing.current = false;
            widthSet(chartViewportElement.offsetWidth);
          });
        }
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  return (
    <TimeSeriesChartContext.Provider
      value={{ ...context, dateEnd, dateStart, months, width, ...props }}
    >
      <Grid>
        <GridColumn>
          <Box sx={{ display: "flex" }}>
            <YAxis />
            <Box
              ref={chartViewport}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <TimeSeriesLines />
              <TimeSeriesLabels />
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 3
            }}
          >
            <LabelXAxis />
            <Legend />
          </Box>
        </GridColumn>
      </Grid>
    </TimeSeriesChartContext.Provider>
  );
};
