import {
  Box,
  Divider,
  Grid,
  GridColumn,
  Headline,
  List,
  ListItem,
  Text
} from "@modernatx/ui-kit-react";
import React from "react";

import { TextAndListProps } from "@/types/Block";

export const TextAndList = ({
  contentBackground,
  disclaimer,
  listItems,
  text,
  title
}: TextAndListProps) => {
  if (!title || !listItems) {
    return null;
  }
  return (
    <>
      <Grid>
        <GridColumn
          sx={{
            borderRadius: "medium",
            bg: contentBackground,
            mb: 5,
            py: 8
          }}
        >
          <Grid>
            <GridColumn
              size={[4, 8, 5]}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  paddingInlineEnd: [8, 8, 0],
                  paddingInlineStart: 8
                }}
              >
                <Headline as="h3" level={5} sx={{ mb: 2 }} variant="bold">
                  {title}
                </Headline>
                {text?.map((textChunk, i) => (
                  <Text
                    key={i}
                    as="p"
                    sx={{
                      color: "text02",
                      whiteSpace: "break-spaces"
                    }}
                  >
                    {textChunk}
                  </Text>
                ))}
              </Box>
            </GridColumn>
            <GridColumn size={[4, 8, 6]} push={[0, 0, 1]} sx={{ justifyContent: "space-around" }}>
              <Box
                sx={{
                  paddingInlineEnd: 8,
                  paddingInlineStart: [8, 8, 0]
                }}
              >
                <Box
                  sx={{
                    columnCount: [1, 1, 2],
                    columnGap: (theme) => `${theme.sizes.gridDesktopColumnGutter}px`,
                    my: [3, 3, 0]
                  }}
                >
                  <List
                    size="md"
                    sx={{
                      color: "text02",
                      fontWeight: "medium"
                    }}
                    variant="unordered-accent"
                  >
                    {listItems?.map((item, i) => (
                      <ListItem key={i} sx={{ whiteSpace: "break-spaces" }}>
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {disclaimer && (
                  <>
                    <Divider sx={{ my: 3 }} />
                    {disclaimer?.map((disclaimerChunk, i) => (
                      <Text key={i} as="p" size="sm" sx={{ color: "text02", my: 2 }}>
                        {disclaimerChunk}
                      </Text>
                    ))}
                  </>
                )}
              </Box>
            </GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </>
  );
};
