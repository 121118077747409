import { DropdownOption } from "@modernatx/ui-kit-react";
import { debounce } from "lodash";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";

export const useLocationSearch = ({
  searchValue,
  searchValueSet,
  searchOptionSelect
}: {
  searchValue: string;
  searchValueSet: (value: string) => void;
  searchOptionSelect: (option: DropdownOption) => void;
}) => {
  const [showNoResults, setShowNoResults] = React.useState<boolean>(false);
  const [pendingSubmit, setPendingSubmit] = React.useState<boolean>(false);

  const { isFetchingSuggestions, searchSuggestions } = useAutocompleteSearch(searchValue);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchValueSet(event.target.value);
  };

  const searchSuggestionsList = searchSuggestions.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id
  }));

  const handleSearchSuggestionClick = React.useCallback(
    (option: DropdownOption) => {
      /* 
      Need this set timout to handle when our keyboard finishes composition event for special characters (like japanese)
      It waits to set search options till after composition event is finished from user. 
      This avoids a weird bug where we update state of the input to be our option we've selected, and THEN the browser composition end event fires, adding special characters to the front of the string.
      Read about composiiton events here: https://developer.mozilla.org/en-US/docs/Web/API/Element/composition_event

      known bug still exists for japan that causes input to be focused after pressing enter. Will continue to investigate
      */

      setTimeout(() => searchOptionSelect(option), 1);
    },
    [searchOptionSelect]
  );

  const handleSearchSubmit = React.useCallback(() => {
    if (isFetchingSuggestions) {
      // If autocomplete search is pending, wait to submit until that fetch is complete.
      setPendingSubmit(true);
    } else if (searchSuggestionsList.length > 0) {
      const defaultOption = searchSuggestionsList[0];
      handleSearchSuggestionClick(defaultOption!);
    }
  }, [isFetchingSuggestions, searchSuggestionsList, handleSearchSuggestionClick]);

  // Handle pending submit once suggestions are fetched. This supports the case
  // where a user submits their search before the suggestions are finished fetching.
  React.useEffect(() => {
    if (pendingSubmit && !isFetchingSuggestions) {
      handleSearchSubmit();
      setPendingSubmit(false);
    }
  }, [pendingSubmit, isFetchingSuggestions, handleSearchSubmit]);

  // Handle "No Results" when search suggestions are empty.
  React.useEffect(() => {
    setShowNoResults(
      !isFetchingSuggestions && searchSuggestionsList.length === 0 && searchValue.length > 1
    );
  }, [searchSuggestionsList, searchValue, isFetchingSuggestions]);

  return {
    handleSearchChange,
    handleSearchSubmit,
    handleSearchSuggestionClick,
    searchSuggestionsList,
    showNoResults
  };
};

interface SearchSuggestion {
  description: string;
  place_id: string;
}

const fetchAutocompleteSearch = debounce(
  async (searchParams: URLSearchParams, callback: (data: SearchSuggestion[]) => void) => {
    return fetch(`/api/location-search-autocomplete?${searchParams.toString()}`)
      .then((response) => response.json())
      .then((data: { predictions: SearchSuggestion[] }) => {
        callback(data.predictions);
      });
  },
  200
);

function useAutocompleteSearch(input: string = "") {
  const { country, language } = useExperience();
  const [searchSuggestions, setSearchSuggestions] = React.useState<{
    [input: string]: SearchSuggestion[];
  }>({});

  const fetchCallback = React.useCallback(
    (data: SearchSuggestion[]) => {
      setSearchSuggestions((prev) => ({
        ...prev,
        [input]: data
      }));
    },
    [input]
  );

  React.useEffect(() => {
    if (input && input.length > 1 && country && language) {
      fetchAutocompleteSearch(new URLSearchParams({ country, input, language }), fetchCallback);
    } else {
      fetchCallback([]);
    }
  }, [country, input, language, fetchCallback]);

  return {
    isFetchingSuggestions: !searchSuggestions[input],
    searchSuggestions: searchSuggestions[input] || []
  };
}
