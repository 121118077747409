import { Box, Button, Grid, GridColumn, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { TableProps } from "@/types/Block";
import { BlockTableGroup } from "@/types/BlockTable";

import { BlockText } from "../BlockText";

interface BlockTableGroupProps extends BlockTableGroup {
  selectedIndex: number;
  showAllColumns: boolean;
}

const TableHeadCells: React.FC<{
  cells: BlockTableGroupProps["rows"][0];
  selectedIndex: number;
  showAllColumns: boolean;
}> = ({ cells, selectedIndex, showAllColumns }) => {
  const headHeight = "80px";
  const growTotal = React.useMemo<number>(() => {
    return cells.reduce((total, row) => (row.grow || 1) + total, 0);
  }, [cells]);

  return (
    <>
      {cells.map(({ grow, ...text }, j) => (
        <Box
          key={j}
          as="th"
          sx={{
            display: [
              showAllColumns ? "revert" : j === 0 || j - 1 === selectedIndex ? "revert" : "none",
              "revert",
              "revert"
            ],
            width: [
              showAllColumns ? () => `${((grow || 1) / growTotal) * 100}%` : "50%",
              () => `${((grow || 1) / growTotal) * 100}%`,
              null
            ],
            verticalAlign: "bottom"
          }}
        >
          <ThemeOverride mode="dark">
            <Box
              sx={{
                backgroundColor: "background02",
                borderLeft: j !== 0 ? "1px solid" : null,
                borderColor: "accent02",
                color: "text01",
                fontWeight: "bold",
                p: 4,
                textAlign: "center",
                height: () => headHeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box>
                <BlockText as="fragment" {...text} />
              </Box>
            </Box>
          </ThemeOverride>
        </Box>
      ))}
    </>
  );
};

const TableGroup: React.FC<BlockTableGroupProps> = ({
  as,
  rows,
  selectedIndex,
  showAllColumns
}) => {
  switch (as) {
    case "thead":
      return (
        <Box as="thead">
          {rows.map((cells, i) => (
            <Box key={i} as="tr">
              <TableHeadCells
                cells={cells}
                selectedIndex={selectedIndex}
                showAllColumns={showAllColumns}
              />
            </Box>
          ))}
        </Box>
      );
    case "tbody":
      return (
        <Box as="tbody" sx={{ backgroundColor: "background01" }}>
          {rows.map((rowCells, i) => (
            <Box key={i} as="tr">
              {rowCells.map((text, j) => (
                <Box
                  key={j}
                  as="td"
                  sx={{
                    display: [
                      showAllColumns
                        ? "revert"
                        : j === 0 || j - 1 === selectedIndex
                          ? "revert"
                          : "none",
                      "revert",
                      "revert"
                    ],
                    borderLeft: j !== 0 ? "1px solid" : null,
                    borderTop: "1px solid",
                    borderColor: "stroke03",
                    p: 4,
                    textAlign: "center",
                    verticalAlign: "middle"
                  }}
                >
                  <BlockText as="fragment" {...text} />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      );
  }
};

export const TableBlock: React.FC<TableProps> = ({ table, mobileSelectors }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const showAllColumns = !mobileSelectors;

  return (
    <Grid>
      <GridColumn>
        <Box sx={{ display: ["flex", "none", "none"], justifyContent: "center", gap: 2, pb: 3 }}>
          {mobileSelectors?.map((selectorText, i) => (
            <Button
              key={i}
              size="small"
              variant={i === selectedIndex ? "primary" : "secondary"}
              onClick={() => setSelectedIndex(i)}
            >
              <BlockText color={i === selectedIndex ? "cta01Text" : "text02"} text={selectorText} />
            </Button>
          ))}
        </Box>
        <Box
          as="table"
          sx={{
            borderRadius: "medium",
            boxShadow: "boxShadow01",
            overflow: "hidden",
            verticalAlign: "middle"
          }}
        >
          {table.map((tableGroup, i) => (
            <TableGroup
              key={i}
              {...tableGroup}
              selectedIndex={selectedIndex}
              showAllColumns={showAllColumns}
            />
          ))}
        </Box>
      </GridColumn>
    </Grid>
  );
};
