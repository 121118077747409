import {
  Box,
  BoxSx,
  Button,
  DEFAULT_ICONS,
  Divider,
  Icon as UiKitIcon,
  Link,
  List,
  ListItem,
  Notification,
  PICTORIAL_ICONS,
  PictorialIcon,
  Text,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { BlockTextProps } from "@/types/BlockText";

import ModalButton from "./blocks/ModalButton";
import { LocationFinder } from "./LocationFinder/LocationFinder";
import { TextBlock } from "./TextBlock/TextBlock";

export const BlockText: React.FC<BlockTextProps> = (blockTextProps) => {
  const { themeOverrideMode } = blockTextProps;
  return themeOverrideMode ? (
    <ThemeOverride mode={themeOverrideMode}>
      <RenderBlockText {...blockTextProps} />
    </ThemeOverride>
  ) : (
    <RenderBlockText {...blockTextProps} />
  );
};

const BlockAnchor: React.FC<React.PropsWithChildren<BlockTextProps & { sx: BoxSx }>> = ({
  action,
  children,
  href,
  icon,
  iconPosition,
  sx,
  target,
  underline
}) => {
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const handleClick = React.useCallback(async () => {
    await navigator.clipboard.writeText(window.location.href);
    setNotificationOpen(true);
  }, []);
  const handleNotificationDismiss = React.useCallback(() => setNotificationOpen(false), []);
  const isCopyLink = action?.type === "copy-link";

  return (
    <>
      <Link
        href={href}
        icon={icon as keyof typeof DEFAULT_ICONS}
        iconPosition={iconPosition}
        sx={sx}
        target={target}
        variant={underline ? "underline" : "default"}
        onClick={isCopyLink ? handleClick : undefined}
      >
        {children as string}
      </Link>
      {isCopyLink && (
        <ThemeOverride mode="light">
          <Notification
            closeDelay={2000}
            icon="link"
            isOpen={notificationOpen}
            showX={false}
            text={action.props.text}
            onDismiss={handleNotificationDismiss}
          />
        </ThemeOverride>
      )}
    </>
  );
};

const BlockComponent: React.FC<{ component: BlockTextProps["component"] }> = ({ component }) => {
  switch (component?.type) {
    case "location-finder":
      return <LocationFinder {...component.props} />;
    case "modal-button":
      return (
        <ModalButton {...component.props}>
          <RenderBlockText text={[component.props.modalContent]} />
        </ModalButton>
      );
    case "text-block":
      return <TextBlock id={component.props.id} initialValue={component.props.children || []} />;
    default:
      return null;
  }
};

const RenderBlockText: React.FC<BlockTextProps> = (props) => {
  const {
    align,
    alignY,
    alt,
    as,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderColor,
    borderRadius,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderWidth,
    boxShadow,
    buttonSize,
    captions,
    color,
    component,
    display,
    flexDirection,
    fontFamily,
    fontStyle,
    fontVariantNumeric,
    gap,
    grow,
    height,
    href,
    icon,
    iconPosition,
    iconSize,
    justifyContent,
    maxHeight,
    maxWidth,
    mb,
    minHeight,
    minWidth,
    mt,
    outlineColor,
    outline,
    overflowWrap,
    poster,
    shrink,
    size,
    spacingBottom,
    spacingEnd,
    spacingStart,
    spacingTop,
    src,
    start,
    text,
    textAlign,
    underline,
    variant,
    weight,
    whiteSpace,
    width,
    wordBreak
  } = props;
  const styles: BoxSx = {
    alignItems: as === "flex" ? alignY : null,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderColor,
    borderRadius,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderWidth,
    boxShadow,
    display: as === "flex" ? "flex" : display,
    flexGrow: grow,
    flexShrink: shrink,
    flexDirection,
    fontFamily,
    fontStyle,
    fontVariantNumeric,
    fontWeight: weight || "inherit",
    gap: as === "flex" ? gap : null,
    height,
    justifyContent: as === "flex" ? (justifyContent ? justifyContent : align) : null,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    mb,
    mt,
    outline,
    outlineColor,
    paddingInlineEnd: spacingEnd,
    overflowWrap: overflowWrap ? overflowWrap : "break-word",
    paddingInlineStart: spacingStart,
    pb: spacingBottom,
    pt: spacingTop,
    textAlign: textAlign || align,
    textDecoration: underline ? "underline" : "unset",
    whiteSpace,
    width,
    wordBreak,
    ...((color && { color }) || {}),
    ...(grow === "fill" && {
      "> *": {
        flex: 1
      }
    })
  };

  const renderText = React.useCallback(
    () =>
      Array.isArray(text) ? (
        text.map((textNode, i) => <BlockText key={i} {...textNode} />)
      ) : (
        <>{text}</>
      ),
    [text]
  );

  if (component) {
    if (component.type === "text-block") {
      return <BlockComponent component={component} />;
    }

    return (
      <Box as="div" sx={styles}>
        <BlockComponent component={component} />
      </Box>
    );
  } else if (as === "br") {
    return <Box as="br" sx={{ display: ["none", "none", "block"], ...styles }} />;
  } else if (as === "em") {
    return (
      <Box as="em" sx={styles}>
        <em>{renderText()}</em>
      </Box>
    );
  } else if (as === "sup") {
    return (
      <Box as="sup" sx={{ verticalAlign: "super", fontSize: () => "55%" }}>
        {renderText()}
      </Box>
    );
  } else if (as === "sub") {
    return (
      <Box as="sub" sx={{ verticalAlign: "sub", fontSize: () => "55%" }}>
        {renderText()}
      </Box>
    );
  } else if (as === "a") {
    // We need to remove the styles that will override the link styles
    // even when they are undefined
    if (styles.alignItems === null) {
      delete styles.alignItems;
    }
    if (styles.gap === null) {
      delete styles.gap;
    }
    if (styles.display === undefined) {
      delete styles.display;
    }
    if (styles.textDecoration === "unset") {
      delete styles.textDecoration;
    }
    return (
      <BlockAnchor {...props} sx={styles}>
        {renderText()}
      </BlockAnchor>
    );
  } else if (as === "ordered" || as === "unordered" || as === "unordered-accent") {
    return (
      <Box sx={styles}>
        <List variant={as} size={size} start={start}>
          {renderText()}
        </List>
      </Box>
    );
  } else if (as === "icon") {
    return (
      <Box
        sx={{
          ...styles,
          // @ts-ignore
          "> svg": { height: "100%" }
        }}
      >
        {icon && <UiKitIcon icon={icon as keyof typeof DEFAULT_ICONS} size={iconSize} />}
      </Box>
    );
  } else if (as === "pictorial-icon") {
    return <Box sx={styles}>{<PictorialIcon icon={icon as keyof typeof PICTORIAL_ICONS} />}</Box>;
  } else if (as === "li") {
    return (
      <ListItem sx={{ ...styles, fontVariantNumeric: "tabular-nums" }}>{renderText()}</ListItem>
    );
  } else if (as === "img") {
    // border radius can only be applied to a div with a background image, so we need to
    // render the image as a background image in a div. The image is also rendered as an
    // img tag with "visibility: hidden" to give the parent div the right height and width
    if (borderRadius) {
      return (
        <Box
          as="div"
          role={!alt ? "presentation" : undefined}
          sx={{
            ...styles,
            backgroundImage: `url(${src})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
          }}
        >
          <Box
            alt={alt || ""}
            as="img"
            role={!alt ? "presentation" : undefined}
            src={src}
            sx={{ visibility: "hidden", width: "100%", height: "100%" }}
          />
        </Box>
      );
    }
    return (
      <Box
        alt={alt || ""}
        as="img"
        role={!alt ? "presentation" : undefined}
        src={src}
        sx={styles}
      />
    );
  } else if (as === "button") {
    return (
      <Box sx={styles}>
        <Button
          href={href}
          icon={icon as keyof typeof DEFAULT_ICONS}
          iconPosition={iconPosition}
          size={buttonSize}
          variant={variant}
        >
          {renderText()}
        </Button>
      </Box>
    );
  } else if (as === "video") {
    return (
      <Box key={src} sx={styles}>
        <video
          controls={true}
          poster={poster}
          style={{ width: "100%", height: "auto", borderRadius: 8 }}
        >
          <source src={src} />
          {captions?.map((caption) => (
            <track
              key={caption.label}
              label={caption.label}
              kind="subtitles"
              srcLang={caption.language}
              src={caption.source}
            />
          ))}
        </video>
      </Box>
    );
  } else if (as === "divider") {
    return (
      <Box sx={styles}>
        <Divider />
      </Box>
    );
  } else if (as === "fragment") {
    return <>{renderText()}</>;
  }

  return (
    <Text as={as === "flex" ? "div" : as} size={size} sx={styles}>
      {renderText()}
    </Text>
  );
};
