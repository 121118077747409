import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { MultiVideoPlayerProps, VideoProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import { VideoDetails } from "../covid/VideoDetails";
import { VideoPlayer } from "../covid/VideoPlayer";
import { VideoThumbnail } from "../covid/VideoThumbnail";

export const MultiVideoPlayer: React.FC<MultiVideoPlayerProps> = ({
  videos = [],
  title,
  downloadButtonText
}) => {
  const [selectedVideo, setSelectedVideo] = React.useState<VideoProps>(
    videos[0] || { url: "", title: "", length: "", thumbnail: "", description: "" }
  );
  const [isDownloading, setIsDownloading] = React.useState(false);
  const thumbnailContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollToThumbnail = (index: number) => {
    const thumbnail = document.getElementById(`thumbnail-${index}`);
    if (thumbnail) {
      thumbnail.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "nearest"
      });
    }
  };

  const handleDownloadVideo = async () => {
    try {
      setIsDownloading(true);
      const videoUrl = selectedVideo.url;
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const downloadTitle = selectedVideo.title || "video";
      link.href = url;
      link.setAttribute("download", `${downloadTitle}.mp4`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error downloading the video:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Grid>
      <GridColumn size={[4, 8, 12]}>
        <Box sx={{ paddingBottom: 5 }}>
          <BlockText text={title} />
        </Box>
      </GridColumn>
      <GridColumn size={[4, 8, 12]}>
        <Box
          ref={thumbnailContainerRef}
          sx={{
            overflowX: "auto",
            display: ["flex", "flex", "none"],
            flexDirection: "row",
            marginBottom: 5,
            // @ts-ignore
            "::-webkit-scrollbar": { display: "none" }
          }}
        >
          {videos.map((video, index) => (
            <VideoThumbnail
              key={index}
              video={video}
              index={index}
              isSelected={selectedVideo.url === video.url}
              isMobile={true}
              videoCount={videos.length}
              onClick={() => {
                setSelectedVideo(video);
                scrollToThumbnail(index);
              }}
            />
          ))}
        </Box>
      </GridColumn>
      <GridColumn size={[4, 8, 8]}>
        <VideoPlayer url={selectedVideo.url} poster={selectedVideo.thumbnail} />
        <VideoDetails
          video={selectedVideo}
          downloadButtonText={downloadButtonText}
          isDownloading={isDownloading}
          onDownload={handleDownloadVideo}
        />
      </GridColumn>
      <GridColumn size={[4, 8, 4]}>
        <Box
          ref={thumbnailContainerRef}
          sx={{
            overflowY: "auto",
            maxHeight: 190,
            display: ["none", "none", "block"]
          }}
        >
          {videos.map((video, index) => (
            <VideoThumbnail
              key={index}
              video={video}
              index={index}
              isSelected={selectedVideo.url === video.url}
              isMobile={false}
              videoCount={videos.length}
              onClick={() => setSelectedVideo(video)}
            />
          ))}
        </Box>
      </GridColumn>
    </Grid>
  );
};
