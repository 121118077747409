import { Box, Button, Headline, Link, Modal, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { useExitModal } from "../../hooks/useExitModal";
import { ReplaceText } from "./ReplaceText";

const ExitModal: React.FC = () => {
  const { exitModal, isExitModalVisible, openExitModal, exitLink } = useExitModal();

  const replace = React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      heading: ({ children }) => (
        <Headline as="h3" level={1} sx={{ pb: 2 }}>
          {children}
        </Headline>
      ),
      link: ({ children }) => (
        <Link href={exitModal?.privacyLink} target="_blank">
          {children}
        </Link>
      )
    }),
    [exitModal?.privacyLink]
  );
  return (
    <Modal isOpen={isExitModalVisible} onDismiss={() => openExitModal(false)}>
      <Box sx={{ py: [8, 10, 15], px: [8, 10, 20] }}>
        {exitModal?.text &&
          exitModal?.text.map((text, i, exitModalText) => (
            <Text
              key={i}
              as="p"
              sx={{
                mb: i === exitModalText.length - 1 ? 6 : undefined,
                mt: i === 1 ? 2 : undefined
              }}
            >
              <ReplaceText replace={replace}>{text}</ReplaceText>
            </Text>
          ))}
        <Button
          // The data-exiting-link attribute is needed so we can allow this link to exit
          data-exiting-link={true}
          external={true}
          href={exitLink}
          size="small"
          target="_blank"
        >
          {exitModal?.buttonText ?? ""}
        </Button>
      </Box>
    </Modal>
  );
};
export default ExitModal;
