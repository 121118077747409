import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { LocationFinderBannerBlockProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import { LocationFinder } from "../LocationFinder/LocationFinder";

export const LocationFinderBanner: React.FC<LocationFinderBannerBlockProps> = ({
  locationFinder,
  text
}) => {
  return (
    <Grid>
      <GridColumn>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <BlockText text={text} />
        </Box>
        {locationFinder && (
          <Grid>
            <GridColumn size={[4, 6, 6]} push={[0, 1, 3]}>
              <LocationFinder {...locationFinder} />
            </GridColumn>
          </Grid>
        )}
      </GridColumn>
    </Grid>
  );
};
