import React from "react";

import { BlockProps } from "@/types/Block";

import { Gradient } from "../../../hooks/useCssColorGradient";

export interface RsvContent {
  blocks: BlockProps[];
  heroImage?: {
    colorBackground: string;
    colorGradient?: Gradient[];
    desktop: string;
    desktopBackgroundPosition: string;
    desktopBackgroundRepeat: string;
    desktopBackgroundSize: string;
    mobile: string;
    mobileBackgroundPosition: string;
    mobileBackgroundRepeat: string;
    mobileBackgroundSize: string;
  };
  healthcareProviderText?: string;
  heroImageCTAButtonText?: string;
  healthcareProviderUrl?: string;
  exitModal: {
    buttonText?: string;
    text?: string[];
    privacyLink?: string;
  };
}

const RsvContentContext = React.createContext<RsvContent>({
  blocks: [],
  exitModal: {}
});

export const RsvContentProvider: React.FC<
  React.PropsWithChildren<{
    content: RsvContent;
  }>
> = ({ children, content }) => {
  return <RsvContentContext.Provider value={content}>{children}</RsvContentContext.Provider>;
};

export const useRsvContent = () => React.useContext(RsvContentContext);
