import { Box, Grid, GridColumn, Headline } from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithInfoCardsProps } from "@/types/Block";

import InfoCard from "../rsv/InfoCard";

export const TextWithInfoCards: React.FC<TextWithInfoCardsProps> = ({
  infoCardItems,
  infoCardLines,
  title
}) => {
  if (!title && !infoCardItems?.length) {
    return null;
  }

  return (
    <Box sx={{ pb: [0, 0, 5] }}>
      <Grid>
        <GridColumn>
          {title && (
            <Headline as="h2" level={4} sx={{ pt: [5, 5, 10], pb: 5 }} variant="bold">
              {title}
            </Headline>
          )}
        </GridColumn>
        <>
          {infoCardItems?.map((item, i) => (
            <GridColumn key={i} size={[4, 4, 4]} sx={{ mb: 5 }}>
              <InfoCard
                button={{
                  variant: "secondary",
                  ...item.button
                }}
                lines={infoCardLines}
                title={item.title}
                showIcon={item.showIcon}
              >
                {item.text}
              </InfoCard>
            </GridColumn>
          ))}
        </>
      </Grid>
    </Box>
  );
};
