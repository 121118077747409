import Image from "next/image";
import React from "react";
import { Global } from "theme-ui";

import { config } from "@/config/environment";
import { FinderLocation } from "@/types/FinderLocation";

import { AdvancedMarker } from "./googleMaps/AdvancedMarker";
import { GoogleAPIProvider } from "./googleMaps/GoogleAPIProvider";
import { Map } from "./googleMaps/Map";
import { useFinder } from "./hooks/useFinder";
import MapViewController from "./MapViewController";

const FinderMarker = React.memo(({ active }: { active: boolean }) => {
  return (
    <Image
      alt="Custom Marker"
      src={`/assets/images/finder-icon${active ? "-active" : ""}.svg`}
      width={50}
      height={50}
    />
  );
});
FinderMarker.displayName = "FinderMarker";

export interface FinderMapBlockProps {
  hidden?: boolean;
  latitude: number;
  longitude: number;
  zoom: number;
  zoomControl?: boolean;
  results?: FinderLocation[];
  language?: string;
}

export const FinderMap: React.FC<FinderMapBlockProps> = React.memo(
  ({ hidden, latitude, longitude, zoom, zoomControl = false, results = [], language }) => {
    const { locationSelected, locationSelectedSet } = useFinder();

    const isSelectedLocation = (location?: FinderLocation) => {
      return locationSelected?.customer.id === location?.customer.id;
    };

    const handleMarkerClick = (searchResult: FinderLocation) => () =>
      locationSelectedSet(searchResult);

    return (
      <GoogleAPIProvider apiKey={config.googleMapsApiKey} language={language}>
        {!!results.length && (
          // This Global component is used to style the Google Maps gesture control message.
          // We move it to the right side of the map so that it is not covered by the results list
          <Global
            styles={{
              ".gm-style-mot": {
                width: "50%",
                float: "right",
                textAlign: "center",
                pl: 10,
                pr: 2
              }
            }}
          />
        )}
        <Map
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={zoom}
          disableDefaultUI={true}
          hidden={hidden}
          zoomControl={zoomControl}
          // 8887f0dd602f7e7a is a mapID created by Kaio Barbosa-Chifan, linked to his personal account.
          // If changes are needed that depend on the google cloud map ID, contact kaio.barbosachifan@modernatx.com or
          // simply create and use a new map ID https://console.cloud.google.com/google/maps-apis/studio/maps
          mapId="8887f0dd602f7e7a"
        >
          <MapViewController results={results} selectedLocation={locationSelected} />
          {results.map((searchResult) => (
            <AdvancedMarker
              key={searchResult.customer.id}
              position={{
                lat: searchResult.location.position.lat,
                lng: searchResult.location.position.lon
              }}
              zIndex={isSelectedLocation(searchResult) ? 1000 : null}
              onClick={handleMarkerClick(searchResult)}
            >
              <FinderMarker active={isSelectedLocation(searchResult)} />
            </AdvancedMarker>
          ))}
        </Map>
      </GoogleAPIProvider>
    );
  }
);
FinderMap.displayName = "FinderMap";
