import { useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { BmiCalculatorForm } from "../../calculator/BmiCalculatorForm";
import { UKQuizProps } from "../EligibilityQuiz";

export const BmiCalculatorQuizCard: React.FC<UKQuizProps["bmiCalculatorProps"]> = ({
  weightInputLabel,
  weightInputTitle,
  heightInputLabel,
  heightInputTitle,
  submitButtonText
}) => {
  const { goToSlideId } = useCarouselContext();

  const bmiCallback = React.useCallback(
    (bmi: number) => {
      if (bmi > 40) {
        goToSlideId("EligibleForCovidAndFlu");
      } else {
        goToSlideId("CovidAndFluQuestions");
      }
    },
    [goToSlideId]
  );
  return (
    <BmiCalculatorForm
      bmiCallback={bmiCallback}
      submitButtonText={submitButtonText}
      inputLabels={{
        height: heightInputLabel,
        weight: weightInputLabel
      }}
      inquiryTexts={{
        height: heightInputTitle,
        weight: weightInputTitle
      }}
    />
  );
};
