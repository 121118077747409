import { Box, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { useIsLoadingImages } from "@/hooks/useIsLoadingImages";
import { useIsLoadingPage } from "@/hooks/useIsLoadingPage";
import { BlockProps } from "@/types/Block";

export const BlockTransition: React.FC<React.PropsWithChildren<BlockProps>> = (blockProps) => {
  const { children: childrenInitial, block: blockInitial = {}, props } = blockProps;
  const [transitioning, transitioningSet] = React.useState(false);
  const block = React.useRef<BlockProps["block"]>(blockInitial);
  const children = React.useRef<React.ReactNode>(childrenInitial);
  const keyNext = `${JSON.stringify(blockInitial)}__${JSON.stringify(props)}`;
  const key = React.useRef(keyNext);
  const loadingImages = useIsLoadingImages(blockProps);
  const loadingPage = useIsLoadingPage();
  const shouldTransition = key.current !== keyNext;
  const transitionTimeout = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  React.useEffect(() => {
    if (shouldTransition) {
      transitioningSet(true);

      // We set a timeout to watch for the relative completion of the transition
      // since transitions can overlap, which makes listening on transition
      // complete lossy
      clearTimeout(transitionTimeout.current);

      transitionTimeout.current = setTimeout(() => {
        transitioningSet(false);
      }, 200);
    }
  }, [shouldTransition]);

  // Only update the children if the transition is complete to avoid content shifting
  // while the transition is occurring
  if (!transitioning && !loadingPage && !shouldTransition) {
    children.current = childrenInitial;
    block.current = blockInitial;
  }

  key.current = keyNext;

  return (
    <ThemeOverride mode={block.current?.themeMode || "light"}>
      <Box
        sx={{
          backgroundColor: block.current?.backgroundColor || "background01",
          transition: "background-color 200ms",
          position: "relative"
        }}
      >
        <Box
          sx={{
            backgroundImage: block.current?.backgroundImage,
            backgroundPosition: block.current?.backgroundPosition,
            backgroundRepeat: block.current?.backgroundRepeat,
            backgroundSize: block.current?.backgroundSize,
            opacity: transitioning || loadingImages || loadingPage ? 0 : 1,
            transition: "opacity 200ms"
          }}
        >
          {children.current}
        </Box>
      </Box>
    </ThemeOverride>
  );
};
