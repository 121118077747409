/*
  This component is a wrapper around the Google Maps JavaScript API loader.
  It loads the Google Maps and Marker libraries and provides them to its children via a context.
  A consumer of this context can use the `useMapsLibrary` hook to access the loaded library.
*/
import { Loader } from "@googlemaps/js-api-loader";
import { Box } from "@modernatx/ui-kit-react";
import React from "react";

interface APIProviderProps {
  apiKey: string;
  children: React.ReactNode;
  language?: string;
}

export const GoogleAPIProvider: React.FC<APIProviderProps> = ({
  apiKey,
  children,
  language = "en"
}) => {
  const [apiIsLoading, setApiIsLoading] = React.useState(true);
  const [googleMapsLibrary, setGoogleMapsLibrary] = React.useState<google.maps.MapsLibrary | null>(
    null
  );
  const [markerLibrary, setMarkerLibrary] = React.useState<google.maps.MarkerLibrary | null>(null);

  const loadMapsLibrary = React.useCallback(async () => {
    const loader = new Loader({
      apiKey,
      language,
      version: "quarterly"
    });

    try {
      const maps = (await loader.importLibrary("maps")) as google.maps.MapsLibrary;
      setGoogleMapsLibrary(maps);
    } finally {
      setApiIsLoading(false);
    }
  }, [apiKey, language]);

  const loadMarkerLibrary = React.useCallback(async () => {
    const loader = new Loader({
      apiKey,
      language,
      version: "quarterly"
    });

    try {
      const marker = (await loader.importLibrary("marker")) as google.maps.MarkerLibrary;
      setMarkerLibrary(marker);
    } finally {
      setApiIsLoading(false);
    }
  }, [apiKey, language]);

  React.useEffect(() => {
    loadMapsLibrary();
    loadMarkerLibrary();
  }, [loadMapsLibrary, loadMarkerLibrary]);

  return (
    <GoogleMapsLibraryContext.Provider value={{ map: googleMapsLibrary, marker: markerLibrary }}>
      {/* @ts-ignore */}
      {apiIsLoading ? <Box sx={{ height: ["300px", null, "600px"] }} /> : children}
    </GoogleMapsLibraryContext.Provider>
  );
};

interface GoogleMapsLibraryContextValue {
  marker: google.maps.MarkerLibrary | null;
  map: google.maps.MapsLibrary | null;
}

// Create a context to provide the loaded google maps library
const GoogleMapsLibraryContext = React.createContext<GoogleMapsLibraryContextValue>({
  marker: null,
  map: null
});

// Hooks to easily access the loaded google maps library
export const useMapsLibrary = () => {
  const context = React.useContext(GoogleMapsLibraryContext);
  if (context === null) {
    throw new Error("useGoogleMaps must be used within a GoogleAPIProvider");
  }
  return context.map;
};

// Hooks to easily access the loaded google marker library
export const useMarkerLibrary = () => {
  const context = React.useContext(GoogleMapsLibraryContext);
  if (context === null) {
    throw new Error("useMarkerLibrary must be used within a GoogleAPIProvider");
  }
  return context.marker;
};
