import { Box, BoxSx, Link, List, ListItem, Text } from "@modernatx/ui-kit-react";
import React from "react";
import type { Descendant } from "slate";
import type { RenderElementProps, RenderLeafProps } from "slate-react";

interface TextBlockRenderContextValue {
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  renderTextBlock: (id: string, initialValue: Descendant[]) => JSX.Element;
}

const TextBlockRenderContext = React.createContext<TextBlockRenderContextValue>({
  renderElement: () => <></>,
  renderLeaf: () => <></>,
  renderTextBlock: () => <></>
});

interface TextBlockRenderProviderProps {
  editing: boolean;
  children: React.ReactNode;
  renderTextBlock: TextBlockRenderContextValue["renderTextBlock"];
}

export const TextBlockRenderProvider: React.FC<TextBlockRenderProviderProps> = (providerProps) => {
  const { editing, renderTextBlock } = providerProps;
  const renderElement = React.useCallback((props: RenderElementProps) => {
    const { attributes, children, element: elementInitial } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children: _childrenOmitted, fontSize, start, type, ...element } = elementInitial;
    const styles: BoxSx = {
      display: type === "span" ? "block" : null,
      fontSize,
      lineHeight: fontSize || "inherit",
      ...element
    };

    switch (type) {
      case "li":
        return (
          <ListItem {...attributes} sx={styles}>
            {children}
          </ListItem>
        );
      case "ol":
        return (
          <List {...attributes} start={start} sx={styles} variant="ordered">
            {children}
          </List>
        );
      case "ul":
        return (
          <List {...attributes} sx={styles} variant="unordered">
            {children}
          </List>
        );
      default:
        return (
          <Box as={type as React.ComponentProps<typeof Text>["as"]} {...attributes} sx={styles}>
            {children}
          </Box>
        );
    }
  }, []);

  const renderLeaf = React.useCallback(
    (props: RenderLeafProps) => {
      const { leaf: leafInitial } = props;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { fontSize, type, text: _omittedText, verticalAlign, ...leaf } = leafInitial;
      const styles: BoxSx = {
        lineHeight: fontSize,
        ...leaf,
        // @ts-ignore
        fontSize: type === "sup" ? "55%" : fontSize,
        verticalAlign: type === "sup" ? "revert" : verticalAlign
      };

      if (!editing && !("whitespace" in styles)) {
        styles.whiteSpace = "pre-line";
      }

      if (leaf.href) {
        return (
          <Link href={editing ? undefined : leaf.href} sx={styles} {...props.attributes}>
            {props.children}
          </Link>
        );
      }

      return (
        <Box as={type || "span"} sx={styles} {...props.attributes}>
          {props.children}
        </Box>
      );
    },
    [editing]
  );

  return (
    <TextBlockRenderContext.Provider value={{ renderElement, renderLeaf, renderTextBlock }}>
      {providerProps.children}
    </TextBlockRenderContext.Provider>
  );
};

export const useTextBlockRendering = () => {
  return React.useContext(TextBlockRenderContext);
};
