import React from "react";

const NavPanelContext = React.createContext({
  navPanelDismiss: () => {},
  navPanelShow: () => {},
  navPanelVisible: false
});

export const NavPanelProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [navPanelVisible, setNavPanelVisible] = React.useState(false);
  const navPanelDismiss = React.useCallback(() => setNavPanelVisible(false), []);
  const navPanelShow = React.useCallback(() => setNavPanelVisible(true), []);
  return (
    <NavPanelContext.Provider value={{ navPanelDismiss, navPanelShow, navPanelVisible }}>
      {children}
    </NavPanelContext.Provider>
  );
};

export const useNavPanel = () => React.useContext(NavPanelContext);
