import { Box } from "@modernatx/ui-kit-react";

import { VideoProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const VideoThumbnail: React.FC<{
  video: VideoProps;
  index: number;
  isSelected: boolean;
  onClick: () => void;
  isMobile: boolean;
  videoCount: number;
}> = ({ video, index, isSelected, onClick, isMobile, videoCount }) => {
  const backgroundColor = isSelected ? "background02" : "transparent";

  const borderLeft = isMobile ? "1px solid" : "none";
  const borderRight = isMobile && index === videoCount - 1 ? "1px solid" : "none";
  const borderTop = !isMobile ? "1px solid" : "none";
  const borderBottom = !isMobile && index === videoCount - 1 ? "1px solid" : "none";

  const mobileTextStyles = {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "3",
    textOverflow: "ellipsis"
  };

  const titleStyles = isMobile ? mobileTextStyles : {};

  return (
    <Box
      key={`thumbnail-${index}`}
      id={`thumbnail-${index}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        padding: 3,
        minWidth: 80,
        cursor: "pointer",
        borderLeft,
        borderRight,
        borderTop,
        borderBottom,
        borderColor: "stroke02",
        ":active": {
          backgroundColor: "background02"
        },
        ":hover": {
          backgroundColor: "background02"
        },
        backgroundColor
      }}
      onClick={onClick}
    >
      <Box sx={{ width: "50%" }}>
        <Box
          as="img"
          src={video.thumbnail}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "top left"
          }}
        />
      </Box>

      <Box sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
        <Box sx={titleStyles}>
          <BlockText text={video.title} />
        </Box>
        <BlockText text={video.length} weight="light" spacingTop={2} />
      </Box>
    </Box>
  );
};
