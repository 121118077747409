import {
  Grid,
  GridColumn,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from "@modernatx/ui-kit-react";

import { TableUIProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const TableUI: React.FC<TableUIProps> = ({
  alignment,
  headerCells,
  bodyCells,
  useMobileButtons = false,
  headerButtonTitles,
  defaultVisibleColumnIndex
}) => {
  const headers =
    useMobileButtons && headerButtonTitles && headerButtonTitles.length > 0
      ? headerButtonTitles.map((button, i) => <BlockText key={i} {...button} color="inherit" />)
      : undefined;

  return (
    <Grid>
      <GridColumn>
        <Table
          alignment={alignment}
          useMobileButtons={useMobileButtons}
          headerButtonTitles={headers}
          defaultVisibleColumnIndex={defaultVisibleColumnIndex}
        >
          <TableHeaderRow>
            <TableRow>
              {headerCells.map((cell, i) => (
                <TableHeaderCell
                  key={i}
                  alignment={cell.alignment}
                  grow={cell.grow}
                  isAlwaysVisible={cell.isAlwaysVisible || false}
                >
                  <BlockText text={cell.text} />
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeaderRow>
          <TableBody>
            {bodyCells.map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell
                    key={j}
                    alignment={cell.alignment}
                    grow={cell.grow}
                    isAlwaysVisible={cell.isAlwaysVisible || false}
                  >
                    <BlockText text={cell.text} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GridColumn>
    </Grid>
  );
};
