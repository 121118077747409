import { Box } from "@modernatx/ui-kit-react";

import { BasicVideoPlayerProps } from "@/types/Block";

export const BasicVideoPlayer: React.FC<BasicVideoPlayerProps> = ({
  src,
  type = "video/mp4",
  captions,
  poster
}) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <video
        controls={true}
        poster={poster}
        style={{ width: "100%", height: "auto", borderRadius: 8 }}
      >
        <source src={src} type={type} />
        {captions?.map((caption) => (
          <track
            key={caption.label}
            label={caption.label}
            kind="subtitles"
            srcLang={caption.language}
            src={caption.source}
          />
        ))}
      </video>
    </Box>
  );
};
