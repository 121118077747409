import {
  Box,
  ContentItem,
  ContentStrip,
  Grid,
  GridColumn,
  Headline
} from "@modernatx/ui-kit-react";
import React from "react";

import { TextWithContentStripProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const TextWithContentStrip: React.FC<TextWithContentStripProps> = (props) => {
  if (!props) {
    return null;
  }

  const { contentItems, title } = props;

  return (
    <Box sx={{ py: !!props.title ? [5, 5, 10] : null }}>
      {!!props.title && (
        <Grid>
          <GridColumn>
            <Headline as="h2" level={4} variant="bold">
              {title}
            </Headline>
          </GridColumn>
        </Grid>
      )}
      <ContentStrip>
        {contentItems?.map((item, i) => (
          <ContentItem
            key={i}
            image={item.image}
            imageFocalPointX={item.imageFocalPointX}
            imageFocalPointY={item.imageFocalPointY}
            title={item.title ? <BlockText {...item.title} /> : undefined}
          >
            <Box sx={{ mt: 2 }}>
              <BlockText {...item.text} />
            </Box>
          </ContentItem>
        ))}
      </ContentStrip>
    </Box>
  );
};
