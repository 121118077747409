import { Button, Checkbox } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockText } from "../../../../BlockText";
import { UKQuizProps } from "../../EligibilityQuiz";

type CheckboxCardProps = UKQuizProps["covidAndFluQuestionsProps"] & {
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, label: string) => void;
  checkboxStates: Record<string, boolean>;
  handleClick: () => void;
};
export const CheckboxCard: React.FC<CheckboxCardProps> = ({
  checkboxItems,
  checkboxStates,
  handleCheckboxChange,
  handleClick,
  title
}) => {
  return (
    <>
      <BlockText text={title} />
      {checkboxItems.map((item) => {
        return (
          <Checkbox
            key={item.label}
            label={<BlockText as="div" text={item.label} size="md" />}
            value={checkboxStates[item.label]}
            onChange={(event) => handleCheckboxChange(event, item.label)}
          />
        );
      })}

      <Button onClick={handleClick}>
        <BlockText text={"Next"} />
      </Button>
    </>
  );
};
