export interface NavGroupItemProps {
  children: React.ReactElement[] | React.ReactElement;
  navButtonLabel: string;
}

const NavGroupItem: React.FC<NavGroupItemProps> = ({ children }) => {
  return <>{children}</>;
};

export default NavGroupItem;
