import { Country } from "@/types/Country";
import { Language } from "@/types/Language";

import { countryCodeMap } from "./country";

export const getCountryName = (countryCode: Country, language?: Language): string => {
  const nativeName = countryCodeMap[countryCode]?.native || "";
  const englishName = countryCodeMap[countryCode]?.en || nativeName;

  if (language === "en" && englishName !== nativeName) {
    return `${englishName} (${nativeName})`;
  }

  const currentLanguageName =
    language &&
    countryCodeMap[countryCode]?.[language] &&
    countryCodeMap[countryCode]?.[language] !== nativeName
      ? countryCodeMap[countryCode][language]
      : nativeName;

  if (currentLanguageName !== nativeName) {
    return `${englishName} (${currentLanguageName})`;
  }

  return englishName === nativeName ? englishName : `${englishName} (${nativeName})`;
};
