import React from "react";

/**
 * A React hook that fires a callback function whenever the user clicks on
 * outside of a given HTML element.
 * @param element React reference to the HTML element
 * @param onClickOutside the callback function fired when the user clicks
 * outside of the referenced HTML element
 * @example
 * const element = React.useRef<HTMLDivElement>(null);
 * const [hasClickedOutside, setHasClickedOutside] = React.useState(false);
 *
 * useClickOutside(element, () => setHasClickedOutside(true));
 *
 * <Box ref={element} />
 */
export const useClickOutside = (
  element: React.RefObject<HTMLElement>,
  onClickOutside: () => void
): void => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        // Don't trigger if the target has been removed entirely
        document.contains(e.target as HTMLElement) &&
        !element.current?.contains(e.target as HTMLElement)
      ) {
        onClickOutside();
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [element, onClickOutside]);
};
