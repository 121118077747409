import { Box, Divider, Modal, Target } from "@modernatx/ui-kit-react";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { useAvailableLanguages } from "@/hooks/useAvailableLanguages";
import { LanguageModalBlockProps } from "@/types/Block";
import { Language } from "@/types/Language";
import { languageCodeMap } from "@/utils/language";

import { BlockText } from "../BlockText";
import { countryLanguageModalTargetStyles } from "./utils";

export interface LanguageModalProps extends LanguageModalBlockProps {
  isOpen?: boolean;
  onDismiss?: () => void;
}

export const LanguageModal: React.FC<LanguageModalProps> = ({
  title,
  isOpen = false,
  onDismiss = () => {}
}) => {
  const { alternates = [], country, language } = useExperience();

  const availableLanguages = useAvailableLanguages();

  const selectableLanguages = Array.from(availableLanguages).map((code) => ({
    code,
    name: languageCodeMap[code]
  }));

  const getLanguageHref = React.useMemo(() => {
    return (languageCode: Language) => {
      const newPath = alternates.find(
        (alt) => alt.country === country && alt.language === languageCode
      )?.url;
      let href;
      if (newPath) {
        const url = new URL(newPath);
        href = url.pathname;
      }
      return href;
    };
  }, [alternates, country]);

  return (
    <Modal isOpen={isOpen} size="xs" onDismiss={onDismiss}>
      <Box sx={{ py: 5, px: 5, height: "100%" }}>
        <Box sx={{ mb: 5 }}>
          <BlockText {...title} />
        </Box>
        <Box>
          <Box>
            <Box sx={{ pb: 4 }}>
              {language && (
                <BlockText text={[{ text: languageCodeMap[language], weight: "medium" }]} />
              )}
            </Box>
            <Divider />
          </Box>
          <Box sx={{ maxHeight: 80, overflowY: "auto" }}>
            {selectableLanguages.map(({ code, name }, index) => (
              <Box key={index} onClick={onDismiss}>
                <Target
                  sx={{
                    ...countryLanguageModalTargetStyles,
                    pt: index === 0 ? 4 : 2,
                    pb: index === selectableLanguages.length - 1 ? 0 : 2
                  }}
                  href={getLanguageHref(code)}
                >
                  <BlockText text={[{ text: name, weight: "medium" }]} />
                </Target>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
