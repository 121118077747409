import { useRouter } from "next/router";
import React from "react";

export const useIsLoadingPage = () => {
  const router = useRouter();
  const [loading, loadingSet] = React.useState(false);

  React.useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        loadingSet(true);
      }
    };
    const handleComplete = () => loadingSet(false);
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.asPath, router.events]);

  return loading;
};
