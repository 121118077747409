import { Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

import { TextProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const Text: React.FC<TextProps> = ({ align, text }) => {
  return (
    <Grid>
      <GridColumn sx={{ alignItems: align, textAlign: align }}>
        <BlockText text={text} />
      </GridColumn>
    </Grid>
  );
};
