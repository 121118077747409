import React, { createContext, ReactNode, useContext } from "react";

import { Experience } from "@/utils/experience";

const ExperienceContext = createContext<Partial<Experience>>({});

export const ExperienceProvider: React.FC<{
  experience: Partial<Experience>;
  children: ReactNode;
}> = ({ experience, children }) => {
  return <ExperienceContext.Provider value={experience}>{children}</ExperienceContext.Provider>;
};

export const useExperience = () => {
  const context = useContext(ExperienceContext);
  if (!context) {
    throw new Error("useExperience must be used within an ExperienceProvider");
  }
  return context;
};
