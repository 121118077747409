import { Button, useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockText } from "@/components/BlockText";

import { UKQuizProps } from "../EligibilityQuiz";
import { useEligibility } from "../hooks/useEligibility";
import { FlexColWithGap } from "./common/FlexColWithGap";

export const FluQuestion: React.FC<UKQuizProps["fluQuestionProps"]> = ({ title }) => {
  const { eligibleForCovid, eligibleForFlu, setEligibleForFlu } = useEligibility();
  const { goToSlideId } = useCarouselContext();
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  const handleClick = (buttonLabel: "yes" | "no") => {
    setButtonClicked(true);
    if (buttonLabel === "yes") {
      setEligibleForFlu(true);
    } else {
      setEligibleForFlu(false);
    }
  };

  React.useEffect(() => {
    if (buttonClicked) {
      if (eligibleForCovid && eligibleForFlu) {
        goToSlideId("EligibleForCovidAndFlu");
      } else if (eligibleForCovid) {
        goToSlideId("EligibleForCovid");
      } else if (eligibleForFlu) {
        goToSlideId("EligibleForFlu");
      } else {
        goToSlideId("Ineligible");
      }
    }
  }, [eligibleForCovid, buttonClicked, eligibleForFlu, goToSlideId]);
  return (
    <>
      <BlockText text={title} />
      <FlexColWithGap>
        <Button variant="secondary" onClick={() => handleClick("yes")}>
          <BlockText text={"Yes"} />
        </Button>
        <Button variant="secondary" onClick={() => handleClick("no")}>
          <BlockText text={"No"} />
        </Button>
      </FlexColWithGap>
    </>
  );
};
