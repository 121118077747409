import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";

import { NavigationBannerProps } from "@/types/Block";

import { BlockText } from "../BlockText";

export const NavigationBanner: React.FC<NavigationBannerProps> = ({ navItems }) => {
  const router = useRouter();

  return (
    <Grid>
      <GridColumn>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", null, "row"],
            gap: 4
          }}
        >
          {navItems.map((item, i) => {
            if (item.href && item.href !== router.asPath) {
              return (
                <Box
                  key={i}
                  sx={{
                    width: "100%",
                    display: "flex"
                  }}
                >
                  <BlockText {...item} />
                </Box>
              );
            }
            return null;
          })}
        </Box>
      </GridColumn>
    </Grid>
  );
};
