import { useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { UKQuizProps } from "../EligibilityQuiz";
import { useCheckboxes } from "../hooks/useCheckboxes";
import { useEligibility } from "../hooks/useEligibility";
import { CheckboxCard } from "./common/CheckboxCard";

export const CovidQuestions: React.FC<UKQuizProps["covidQuestionsProps"]> = (props) => {
  const { setEligibleForCovid } = useEligibility();
  const { goToSlideId } = useCarouselContext();
  const { numberOfCheckboxesSelected, checkboxStates, handleCheckboxChange } = useCheckboxes(
    props.checkboxItems
  );

  const handleClick = React.useCallback(() => {
    if (numberOfCheckboxesSelected > 0) {
      setEligibleForCovid(true);
    } else {
      setEligibleForCovid(false);
    }
    goToSlideId("FluQuestion");
  }, [numberOfCheckboxesSelected, setEligibleForCovid, goToSlideId]);

  const checkboxCardProps = React.useMemo(() => {
    return {
      checkboxStates,
      handleCheckboxChange,
      handleClick,
      ...props
    };
  }, [checkboxStates, handleCheckboxChange, handleClick, props]);
  return <CheckboxCard {...checkboxCardProps} />;
};
