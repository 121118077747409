import { Box, Divider, FlagIcon, Modal, SearchBar, Target } from "@modernatx/ui-kit-react";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { useCountrySearch } from "@/hooks/useCountrySearch";
import { CountryModalBlockProps } from "@/types/Block";
import { Country } from "@/types/Country";
import { getCountryName } from "@/utils/getCountryName";

import { useAvailableCountries } from "../../hooks/useAvailableCountries";
import { BlockText } from "../BlockText";
import { countryLanguageModalTargetStyles, getFlagIcon } from "./utils";

export interface CountryModalProps extends CountryModalBlockProps {
  isOpen?: boolean;
  onDismiss?: () => void;
}

export const CountryModal: React.FC<CountryModalProps> = ({
  title,
  search,
  isOpen = false,
  onDismiss = () => {},
  noResultsText
}) => {
  const { country, alternates = [], language } = useExperience();

  const availableCountries = useAvailableCountries();
  const { searchTerm, filteredCountries, handleSearchChange, clearSearch } =
    useCountrySearch(availableCountries);

  const currentCountryIcon = country ? getFlagIcon(country) : undefined;
  const currentCountryName = React.useMemo(() => {
    if (!country) {
      return "";
    }
    return getCountryName(country, language);
  }, [country, language]);

  const getCountryHref = React.useMemo(() => {
    return (countryCode: Country) => {
      const newPath = alternates.find((alt) => alt.country === countryCode)?.url;
      let href;
      if (newPath) {
        const url = new URL(newPath);
        href = url.pathname;
      }
      return href;
    };
  }, [alternates]);

  const handleDismiss = () => {
    clearSearch();
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} size="xs" onDismiss={handleDismiss}>
      <Box
        sx={{
          py: 5,
          px: 5,
          height: "100%",
          display: "flex",
          alignSelf: "start",
          flexDirection: "column"
        }}
      >
        <Box sx={{ mb: 5 }}>
          <BlockText {...title} />
        </Box>
        <Box sx={{ height: "100%" }}>
          <Box sx={{ marginRight: 8 }}>
            <SearchBar
              variant="transparent"
              size="small"
              label={search.label}
              value={searchTerm}
              sx={{ mb: 4 }}
              onChange={handleSearchChange}
            />
            <Box sx={{ display: "flex", alignItems: "center", paddingBottom: 4 }}>
              {currentCountryIcon && (
                <FlagIcon icon={currentCountryIcon} size={"lg"} sx={{ paddingInlineEnd: 2 }} />
              )}
              <BlockText text={[{ text: currentCountryName, weight: "medium" }]} />
            </Box>
            <Divider />
          </Box>
          <Box sx={{ height: 80, overflowY: "auto" }}>
            {filteredCountries.length === 0 && (
              <Box sx={{ pt: 4 }}>
                <BlockText text={[{ text: noResultsText, weight: "medium" }]} />
              </Box>
            )}
            {filteredCountries.map(({ countryCode, displayName }, index) => {
              const countryIcon = countryCode && getFlagIcon(countryCode);
              return (
                <Box key={index} onClick={handleDismiss}>
                  <Target
                    sx={{
                      ...countryLanguageModalTargetStyles,
                      pt: index === 0 ? 4 : 2,
                      pb: index === filteredCountries.length - 1 ? 0 : 2
                    }}
                    href={getCountryHref(countryCode)}
                  >
                    {countryIcon && (
                      <FlagIcon icon={countryIcon} size={"lg"} sx={{ paddingInlineEnd: 2 }} />
                    )}
                    <BlockText text={[{ text: displayName, weight: "medium" }]} />
                  </Target>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
