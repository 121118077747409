import React from "react";

type SvgPathPoints = [number, number, number?, number?];

interface SvgPathCommand {
  command: string;
  points: SvgPathPoints;
}

const getRoundedNumber = (num: number) => Math.ceil(num * 100) / 100;

const parsePathD = (d: string): SvgPathCommand[] => {
  const commands = d.match(/[a-df-z][^a-df-z]*/gi);

  return (
    commands?.map((cmd: string) => {
      const command = cmd[0] as string;
      const points = cmd
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(Number) as SvgPathPoints;
      return { command, points };
    }) ?? []
  );
};

function getControlPoint(
  p0: SvgPathPoints,
  p1: SvgPathPoints,
  p2: SvgPathPoints,
  tension: number
): SvgPathPoints {
  const d01 = Math.sqrt(Math.pow(p1[0] - p0[0], 2) + Math.pow(p1[1] - p0[1], 2));
  const d12 = Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));

  const t = tension / 100; // Convert percentage to a value between 0 and 1
  const fa = (t * d01) / (d01 + d12); // Adjusted factor for the control point

  const c1: [number, number] = [p1[0] + fa * (p0[0] - p2[0]), p1[1] + fa * (p0[1] - p2[1])];

  return c1;
}

function smoothPathD(commands: SvgPathCommand[], tension: number): SvgPathCommand[] {
  if (!commands[0]) {
    return [];
  }

  const smoothedCommands: SvgPathCommand[] = [];
  smoothedCommands.push(commands[0]);

  for (let i = 1; i < commands.length - 1; i++) {
    const prevCmd = commands[i - 1];
    const currCmd = commands[i];
    const nextCmd = commands[i + 1];

    if (currCmd?.command.toUpperCase() === "L" && prevCmd && currCmd && nextCmd) {
      const controlPoint = getControlPoint(
        prevCmd.points.slice(-2) as SvgPathPoints,
        currCmd.points.slice(0, 2) as SvgPathPoints,
        nextCmd.points.slice(0, 2) as SvgPathPoints,
        tension
      );

      if (controlPoint) {
        smoothedCommands.push({
          command: "Q",
          points: [controlPoint[0], controlPoint[1], currCmd.points[0], currCmd.points[1]].map(
            getRoundedNumber
          ) as SvgPathPoints
        });
      }
    } else if (currCmd) {
      smoothedCommands.push(currCmd);
    }
  }

  const commandEnd = commands[commands.length - 1];

  if (commandEnd) {
    smoothedCommands.push(commandEnd);
  }

  return smoothedCommands;
}

function buildPathD(commands: SvgPathCommand[]): string {
  return commands.map((cmd) => cmd.command + " " + cmd.points.join(" ")).join(" ");
}

export const useSmoothSvgPath = (d: string, { smoothing }: { smoothing: number }) => {
  return React.useMemo(() => {
    const commands = parsePathD(d);
    const commandsSmoothed = smoothPathD(commands, smoothing);
    return buildPathD(commandsSmoothed);
  }, [d, smoothing]);
};
