import { Box, Button, Grid, GridColumn, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";
import { BmiCalculatorForm } from "../stayontrack/calculator/BmiCalculatorForm";

export interface BmiCalculatorBlockProps {
  buttonTexts: {
    submit: string;
    reset: string;
    infoEligible: string;
    infoEligibleLink: string;
    infoIneligible: string;
    infoIneligibleLink: string;
  };
  inputLabels: {
    height: string;
    weight: string;
  };
  inquiryTexts: {
    weight: Exclude<BlockTextProps["text"], undefined>;
    height: Exclude<BlockTextProps["text"], undefined>;
  };
  bmiCategories: {
    underweight: string;
    healthyWeight: string;
    overweight: string;
    obese: string;
    severelyObese: string;
  };
  resultTexts: {
    eligible: string;
    notEligible: string;
    category: string;
    value: string;
  };
  positiveResultText: BlockTextProps[];
  negativeResultText: BlockTextProps[];
  endText: BlockTextProps[];
}

const BmiResultSection: React.FC<{
  result: {
    bmiValue: string;
    bmiCategory: string;
    isEligible: boolean;
  };
  resultTexts: BmiCalculatorBlockProps["resultTexts"];
  positiveResultText: BmiCalculatorBlockProps["positiveResultText"];
  negativeResultText: BmiCalculatorBlockProps["negativeResultText"];
  buttonTexts: BmiCalculatorBlockProps["buttonTexts"];
  handleReset: () => void;
}> = ({
  result,
  resultTexts,
  buttonTexts,
  positiveResultText,
  negativeResultText,
  handleReset
}) => {
  const eligibilityText = result.isEligible ? resultTexts.eligible : resultTexts.notEligible;
  const resultText = result.isEligible ? positiveResultText : negativeResultText;
  const infoButtonText = result.isEligible ? buttonTexts.infoEligible : buttonTexts.infoIneligible;
  const infoButtonLink = result.isEligible
    ? buttonTexts.infoEligibleLink
    : buttonTexts.infoIneligibleLink;
  const infoButtonIcon = result.isEligible ? "arrow-up-right" : "arrow-down";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 8 }}>
      <BlockText
        text={resultTexts.value.replace("{bmiValue}", result.bmiValue)}
        weight="bold"
        size="5xl"
      />
      <Box as="ul" sx={{ paddingInlineStart: 4, mt: 1 }}>
        <Box as="li">
          <BlockText
            text={resultTexts.category.replace("{bmiCategory}", result.bmiCategory)}
            weight="bold"
          />
        </Box>
        <Box as="li">
          <BlockText text={eligibilityText} weight="bold" />
        </Box>
      </Box>
      <BlockText text={resultText} />

      <Box sx={{ display: "flex", flexDirection: "column", mt: 5, gap: 2 }}>
        <Button href={infoButtonLink} icon={infoButtonIcon} iconPosition="right">
          {infoButtonText}
        </Button>
        <Button variant="secondary" onClick={handleReset}>
          {buttonTexts.reset}
        </Button>
      </Box>
    </Box>
  );
};

export const BmiCalculator: React.FC<BmiCalculatorBlockProps> = ({
  buttonTexts,
  inputLabels,
  inquiryTexts,
  bmiCategories,
  resultTexts,
  positiveResultText,
  negativeResultText,
  endText
}) => {
  const [result, setResult] = React.useState<{
    bmiValue: string;
    bmiCategory: string;
    isEligible: boolean;
  } | null>(null);
  const [resetKey, setResetKey] = React.useState(0); // Key to force react to re-render components on form reset
  const resetForm = () => {
    setResetKey((prevKey) => prevKey + 1);
  };

  const getBMICategory = React.useCallback(
    (bmi: number) => {
      if (bmi < 18.5) {
        return bmiCategories.underweight;
      }
      if (bmi < 25) {
        return bmiCategories.healthyWeight;
      }
      if (bmi < 30) {
        return bmiCategories.overweight;
      }
      if (bmi < 40) {
        return bmiCategories.obese;
      }
      return bmiCategories.severelyObese;
    },
    [bmiCategories]
  );

  const bmiCallback = React.useCallback(
    (bmi: number) =>
      setResult({
        bmiValue: bmi.toFixed(2),
        bmiCategory: getBMICategory(bmi),
        isEligible: bmi >= 40
      }),
    [getBMICategory]
  );

  const handleReset = React.useCallback(() => {
    resetForm();
    setResult(null);
  }, []);

  return (
    <Grid sx={{ gap: [10, 0, 0], flexDirection: ["column-reverse", "row", null] }}>
      <ThemeOverride mode="dark">
        <GridColumn
          size={[4, 4, 5]}
          sx={{ backgroundColor: "background01", borderRadius: "large" }}
        >
          {!result ? (
            <BmiCalculatorForm
              key={resetKey}
              bmiCallback={bmiCallback}
              submitButtonText={buttonTexts.submit}
              inputLabels={inputLabels}
              inquiryTexts={inquiryTexts}
              sx={{ p: 8 }}
            />
          ) : (
            <BmiResultSection
              result={result}
              resultTexts={resultTexts}
              buttonTexts={buttonTexts}
              positiveResultText={positiveResultText}
              negativeResultText={negativeResultText}
              handleReset={handleReset}
            />
          )}
        </GridColumn>
      </ThemeOverride>
      <GridColumn size={[4, 4, 7]} sx={{ justifyContent: "center" }}>
        <BlockText text={endText} alignY="center" justifyContent="center" />
      </GridColumn>
    </Grid>
  );
};
