import React, { ReactNode } from "react";

interface EligibilityContextType {
  eligibleForCovid: boolean;
  eligibleForFlu: boolean;
  setEligibleForCovid: (value: boolean) => void;
  setEligibleForFlu: (value: boolean) => void;
  resetKey: number;
  resetQuiz: () => void;
}

export const EligibilityContext = React.createContext<EligibilityContextType | undefined>(
  undefined
);

export const EligibilityProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [eligibleForCovid, setEligibleForCovid] = React.useState<boolean>(false);
  const [eligibleForFlu, setEligibleForFlu] = React.useState<boolean>(false);
  const [resetKey, setResetKey] = React.useState(0); // Key to force react to re-render components on quiz reset

  const resetQuiz = () => {
    setResetKey((prevKey) => prevKey + 1);
  };
  return (
    <EligibilityContext.Provider
      value={{
        eligibleForCovid,
        eligibleForFlu,
        setEligibleForCovid,
        setEligibleForFlu,
        resetKey,
        resetQuiz
      }}
    >
      {children}
    </EligibilityContext.Provider>
  );
};
export const useEligibility = (): EligibilityContextType => {
  const context = React.useContext(EligibilityContext);

  if (!context) {
    throw new Error("useEligibility must be used within an EligibilityProvider");
  }

  return context;
};
