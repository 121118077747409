import React from "react";

import { Country } from "@/types/Country";
import { countryCodeMap } from "@/utils/country";

export const useCountrySearch = (countries: Set<Country>) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredCountries = React.useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return Array.from(countries)
      .map((country) => {
        const nativeName = countryCodeMap[country]?.native || "";
        const englishName = countryCodeMap[country]?.en || nativeName;

        const displayName =
          englishName === nativeName ? englishName : `${englishName} (${nativeName})`;

        return {
          countryCode: country,
          displayName,
          sortKey: englishName.toLowerCase()
        };
      })
      .filter(({ displayName }) => displayName.toLowerCase().includes(lowerCaseSearchTerm))
      .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
      .map(({ countryCode, displayName }) => ({ countryCode, displayName }));
  }, [countries, searchTerm]);

  const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const clearSearch = React.useCallback(() => {
    setSearchTerm("");
  }, []);

  return { searchTerm, filteredCountries, handleSearchChange, clearSearch };
};
