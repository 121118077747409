import {
  Box,
  Button,
  CollapseGroup,
  CollapseItem,
  Grid,
  GridColumn
} from "@modernatx/ui-kit-react";
import React from "react";

import { CollapseItemsProps } from "@/types/Block";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";

export const CollapseItems: React.FC<CollapseItemsProps> = ({
  collapseItems,
  twoColumnLayout,
  sectionTitles
}) => {
  const [section, setSection] = React.useState<number>(0);

  if (!collapseItems?.length) {
    return null;
  }

  const filteredItems = sectionTitles?.length
    ? collapseItems.filter((item) => item.sectionKey?.includes(sectionTitles[section] || ""))
    : collapseItems;

  const renderCollapseItems = (
    itemsArray: {
      text: string | BlockTextProps[];
      title: string | BlockTextProps[];
    }[],
    isTwoColumnLayout?: boolean
  ): JSX.Element[] =>
    itemsArray.map((item, itemIndex) => {
      const title = typeof item.title === "string" ? item.title : <BlockText text={item.title} />;
      return (
        <CollapseItem key={`${section}-${itemIndex}`} title={title}>
          <Box sx={{ pb: 4 }}>
            <BlockText
              color="text02"
              whiteSpace={isTwoColumnLayout ? "pre-wrap" : undefined}
              text={item.text}
            />
          </Box>
        </CollapseItem>
      );
    });

  const gridLayout = () => {
    if (twoColumnLayout) {
      const halfIndex = Math.ceil(filteredItems.length / 2);
      const firstHalf = filteredItems.slice(0, halfIndex);
      const secondHalf = filteredItems.slice(halfIndex);
      return (
        <Grid>
          <GridColumn>
            <CollapseGroup size="md">
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid>
                  <GridColumn size={[4, 8, 6]}>
                    {renderCollapseItems(firstHalf, twoColumnLayout)}
                  </GridColumn>
                  <GridColumn size={[4, 8, 6]}>
                    {renderCollapseItems(secondHalf, twoColumnLayout)}
                  </GridColumn>
                </Grid>
              </Box>
            </CollapseGroup>
          </GridColumn>
        </Grid>
      );
    } else {
      return (
        <Grid>
          <GridColumn>
            <CollapseGroup size="lg">
              {renderCollapseItems(filteredItems, twoColumnLayout)}
            </CollapseGroup>
          </GridColumn>
        </Grid>
      );
    }
  };

  return (
    <Grid>
      <GridColumn>
        {sectionTitles?.length && (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              pb: 5,
              pt: 3,
              gap: 1
            }}
          >
            {sectionTitles?.map((sectionTitle, i) => (
              <Button
                key={i}
                size="small"
                variant={i === section ? "primary" : "secondary"}
                onClick={() => setSection(i)}
              >
                {sectionTitle}
              </Button>
            ))}
          </Box>
        )}
        {gridLayout()}
      </GridColumn>
    </Grid>
  );
};
