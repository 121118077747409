import { Box, Text } from "@modernatx/ui-kit-react";
import React from "react";

interface TwoColumnTextProps {
  children: React.ReactNode;
  disable?: boolean;
}

export const TwoColumnText: React.FC<TwoColumnTextProps> = ({ children, disable }) => {
  return (
    <Box
      sx={{
        columnCount: disable ? 1 : [1, 1, 2],
        gap: (theme) => `${theme.sizes.gridDesktopColumnGutter}px`,
        orphans: 3,
        widows: 3
      }}
    >
      {React.Children.map(children, (child, i) => (
        <Text
          key={i}
          sx={{
            color: "text02",
            mb: 4,
            mt: 0
          }}
        >
          {child}
        </Text>
      ))}
    </Box>
  );
};
