import Head from "next/head";
import React from "react";

import { config } from "@/config/environment";
import { Metadata } from "@/types/Block";

interface RenderMetadataProps extends Metadata {
  renderGoogleRecaptcha?: boolean;
}
export const RenderMetadata: React.FC<RenderMetadataProps> = ({
  title,
  description,
  metaTags,
  favicon,
  renderGoogleRecaptcha = false
}) => {
  React.useEffect(() => {
    const scriptIdAttribute = "GoogleRecaptchaScript";

    // Using next/script would not load the library and using next/head
    // would throw a warning about using scripts in next/head, so we just
    // manually load it on the client instead
    if (renderGoogleRecaptcha && !document.getElementById(scriptIdAttribute)) {
      const script = document.createElement("script");
      script.setAttribute("async", "true");
      script.setAttribute("id", "GoogleRecaptchaScript");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${config.recaptchaSiteKey}`;
      document.head.appendChild(script);
    }
  }, [renderGoogleRecaptcha]);

  return (
    <Head>
      <title>{title}</title>;{description && <meta name="description" content={description} />};
      {metaTags?.map((metaTag, i) => (
        <meta key={i} name={metaTag.name} property={metaTag.property} content={metaTag.content} />
      ))}
      {favicon && <link rel="icon" href={favicon} />}
      {renderGoogleRecaptcha && <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>}
    </Head>
  );
};
