import {
  Box,
  Button,
  Grid,
  GridColumn,
  Headline,
  ShareMenu,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { useSocialShareFunctions } from "@/hooks/useSocialShareFunctions";
import { useTextReplacements } from "@/hooks/useTextReplacements";
import { DiseaseEdHeroProps } from "@/types/Block";

import { useCssColorGradient } from "../../hooks/useCssColorGradient";
import NavButton from "../navigation/NavButton";
import NavPanel from "../navigation/NavPanel";
import { ReplaceText } from "../rsv/ReplaceText";

export const DiseaseEdHero: React.FC<DiseaseEdHeroProps> = ({
  contentBackground,
  colorGradient,
  heroCenterButton,
  shareMenuProps,
  heroTopButton,
  image,
  title,
  titleSize
}) => {
  const gradient = useCssColorGradient(colorGradient);
  const replace = useTextReplacements();
  const { shareToFacebook, shareToLinkedin, shareToX } = useSocialShareFunctions({
    facebookUrl: shareMenuProps?.facebookUrl,
    xShareProps: shareMenuProps?.xShareProps,
    linkedinUrl: shareMenuProps?.linkedinUrl
  });
  return (
    <>
      <ThemeOverride mode="dark">
        <Box
          sx={{
            backgroundColor: () => contentBackground,
            backgroundImage: image?.map(({ src }) => (src ? `url(${src})` : null)),
            backgroundPosition: image?.map(({ position }) => position || null),
            backgroundRepeat: image?.map(({ repeat }) => repeat || null),
            backgroundSize: image?.map(({ size }) => size || null),
            textAlign: "center",
            position: "relative",
            // @ts-ignore a height of this scale isn't available in the UI Kit
            height: "500px"
          }}
        >
          <Box
            sx={{
              background: () => gradient,
              position: "relative",
              height: "100%",
              width: "100%"
            }}
          >
            <Box
              sx={{
                p: 5,
                position: "absolute",
                right: 0,
                top: 0
              }}
            >
              <Box
                sx={{
                  display: ["none", "none", "block"]
                }}
              >
                {heroTopButton && (
                  <Button
                    external={heroTopButton.external}
                    href={heroTopButton.href}
                    size="small"
                    variant={heroTopButton.variant}
                  >
                    {heroTopButton.title}
                  </Button>
                )}
              </Box>
              {heroTopButton?.title && <NavButton />}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between"
              }}
            >
              <Grid
                sx={{
                  alignItems: "flex-end",
                  height: "100%",
                  mb: [15, 15, 20]
                }}
              >
                <GridColumn size={[4, 6, 8]} push={[0, 1, 2]}>
                  <Headline
                    level={1}
                    sx={{
                      fontSize: titleSize ? titleSize : [10, 12, null],
                      textShadow: "textShadow01"
                    }}
                    variant="bold"
                  >
                    <ReplaceText replace={replace}>{title}</ReplaceText>
                  </Headline>
                  {heroCenterButton && (
                    <Box sx={{ mt: 5 }}>
                      <Button
                        external={heroCenterButton.external}
                        href={heroCenterButton.href}
                        size="medium"
                      >
                        {heroCenterButton.title}
                      </Button>
                    </Box>
                  )}
                  {shareMenuProps && (
                    <Box sx={{ display: "flex", justifyContent: "center", pt: 5 }}>
                      <ShareMenu
                        buttonLabel={shareMenuProps.buttonLabel}
                        buttonVariant={shareMenuProps.buttonVariant}
                        copyLinkUrl={shareMenuProps.copyLinkUrl}
                        instagramUrl={shareMenuProps.instagramUrl}
                        facebookClickHandler={shareToFacebook}
                        xClickHandler={shareToX}
                        linkedinClickHandler={shareToLinkedin}
                      />
                    </Box>
                  )}
                </GridColumn>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ThemeOverride>
      {heroTopButton && <NavPanel {...heroTopButton} />}
    </>
  );
};
