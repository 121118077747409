import {
  Box,
  Button,
  Grid,
  GridColumn,
  Icon,
  Notification,
  Target,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { ProductHeroProps } from "@/types/Block";

import { BlockText } from "../BlockText";
import { LocationFinder } from "../LocationFinder/LocationFinder";
import NewsletterSignup from "../mresvia/NewsletterSignup";
import { Survey } from "./Survey";

interface HeroLinkProps {
  children?: React.ReactNode;
  icon?: React.ComponentProps<typeof Icon>["icon"];
  href?: string;
  onClick?: React.MouseEventHandler;
}

const HeroLink: React.FC<HeroLinkProps> = ({ children, href, icon, onClick }) => {
  return (
    <Target
      href={href}
      sx={{
        alignItems: "center",
        color: "text01",
        display: "flex",
        fontWeight: "bold",
        py: 2,
        px: 3,
        textDecoration: "underline",
        textDecorationColor: "transparent",
        transition: "colorMedium",
        ":hover": {
          textDecorationColor: "currentColor"
        },
        ":focus-visible": {
          outline: "2px solid transparent",
          outlineColor: "accent01"
        }
      }}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} size="xs" sx={{ color: "cta01", marginInlineEnd: 2 }} />}
      {children}
    </Target>
  );
};

const ShareCopyLink: React.FC<React.PropsWithChildren<{ shareCopySuccess?: string }>> = ({
  children,
  shareCopySuccess
}) => {
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const handleClick = React.useCallback(async () => {
    await navigator.clipboard.writeText(window.location.href);
    setNotificationOpen(true);
  }, []);

  const handleNotificationDismiss = React.useCallback(() => setNotificationOpen(false), []);

  return (
    <>
      <HeroLink icon="link" onClick={handleClick}>
        {children}
      </HeroLink>
      <ThemeOverride mode="light">
        <Notification
          closeDelay={2000}
          icon="link"
          isOpen={notificationOpen}
          showX={false}
          text={shareCopySuccess || ""}
          onDismiss={handleNotificationDismiss}
        />
      </ThemeOverride>
    </>
  );
};

const ShareEmailLink: React.FC<React.PropsWithChildren<{ body?: string; subject?: string }>> = ({
  body,
  children,
  subject
}) => {
  // We can't encode the search params as the email client will use the encoded elements
  // rather than translating them.
  const searchParams = React.useMemo(
    () => [`${body ? `body=${body}` : ""}`, `${subject ? `subject=${subject}` : ""}`].join("&"),
    [body, subject]
  );

  return (
    <HeroLink href={`mailto:?${searchParams}`} icon="mail-to">
      {children}
    </HeroLink>
  );
};

export const ProductHero: React.FC<ProductHeroProps> = ({
  align,
  button,
  image,
  imageAlt,
  imageAspectRatio,
  locationFinder,
  newsletter,
  shareCopy,
  shareCopySuccess,
  shareEmail,
  text,
  shareEmailBody,
  shareEmailSubject,
  survey
}) => {
  return (
    <Grid>
      {!!imageAspectRatio && (
        <GridColumn size={[4, 8, 6]} sx={{ display: ["flex", "flex", "none"] }}>
          <Box
            sx={{
              pb: () => (imageAspectRatio ? `${imageAspectRatio * 100}%` : null)
            }}
          >
            {image && (
              <Box
                as="img"
                src={image}
                alt={imageAlt}
                sx={{ width: "100%", position: "absolute" }}
              />
            )}
          </Box>
        </GridColumn>
      )}
      <GridColumn
        size={imageAspectRatio ? [4, 8, 6] : undefined}
        sx={{ alignItems: align, textAlign: align }}
      >
        <BlockText text={text} />
        {newsletter && <NewsletterSignup {...newsletter} />}
        {button && (
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row"]
            }}
          >
            <Button href={button.href} target="_blank" sx={{ flexGrow: 0 }}>
              {button.title}
            </Button>
          </Box>
        )}
        {locationFinder && (
          <Box
            sx={{
              width: locationFinder.width || ["100%", "100%", "50%"],
              textAlign: "start"
            }}
          >
            <LocationFinder {...locationFinder} />
          </Box>
        )}
        {(shareCopy || shareEmail) && (
          <Box
            sx={{
              display: "flex",
              py: 5,
              marginInlineStart: !align || align === "left" ? [0, 0, -3] : null,
              justifyContent: ["space-evenly", "space-evenly", "inherit"]
            }}
          >
            {shareEmail && (
              <ShareEmailLink body={shareEmailBody} subject={shareEmailSubject}>
                {shareEmail}
              </ShareEmailLink>
            )}
            {shareCopy && (
              <ShareCopyLink shareCopySuccess={shareCopySuccess}>{shareCopy}</ShareCopyLink>
            )}
          </Box>
        )}
        {survey && <Survey {...survey} />}
      </GridColumn>
      {!!imageAspectRatio && (
        <GridColumn
          size={[4, 8, 6]}
          sx={{
            display: ["none", "none", "flex"],
            justifyContent: "center"
          }}
        >
          <Box
            sx={{
              pb: () => (imageAspectRatio ? `${imageAspectRatio * 100}%` : null),
              position: "relative",
              display: "flex",
              justifyContent: "right"
            }}
          >
            {image && (
              <Box
                as="img"
                src={image}
                alt={imageAlt}
                sx={{ height: "100%", position: "absolute" }}
              />
            )}
          </Box>
        </GridColumn>
      )}
    </Grid>
  );
};
