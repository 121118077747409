import { Box, Icon, NavPanel as UIKitNavPanel, Target } from "@modernatx/ui-kit-react";
import React from "react";

import { ButtonBlockProps } from "@/types/Block";

import { useNavPanel } from "../../hooks/useNavPanel";

const NavPanel = (props: ButtonBlockProps) => {
  const { navPanelDismiss, navPanelVisible } = useNavPanel();
  const { title: healthcareProviderText, href: healthcareProviderUrl } = props;
  return (
    <UIKitNavPanel visible={navPanelVisible} onDismiss={navPanelDismiss}>
      <Box as="ul">
        <Box as="li">
          <Target
            href={healthcareProviderUrl}
            sx={{
              display: "flex",
              fontSize: 3,
              fontWeight: "bold",
              lineHeight: 3,
              width: "100%"
            }}
            target="_blank"
          >
            {healthcareProviderText}
            <Box as="span" sx={{ marginInlineStart: "auto" }}>
              <Icon size="sm" icon="arrow-up-right" />
            </Box>
          </Target>
        </Box>
      </Box>
    </UIKitNavPanel>
  );
};

export default NavPanel;
