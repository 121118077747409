import { Box, Button, PictorialIcon, Text } from "@modernatx/ui-kit-react";

interface InfoCardButtonProps extends Omit<React.ComponentProps<typeof Button>, "children"> {
  href?: string;
  title: string;
}

export interface InfoCardProps {
  button: InfoCardButtonProps;
  children?: React.ReactNode;
  description?: string;
  lines?: number;
  title: string;
  showIcon?: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({
  button,
  children,
  description,
  title,
  showIcon = true
}) => {
  const iconVariant = button.external ? "humanity-globe" : "healthcare-health-report";

  return (
    <Box
      sx={{
        alignItems: "start",
        bg: "background01",
        borderColor: "stroke02",
        borderRadius: "medium",
        borderStyle: "solid",
        borderWidth: "1px",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        p: 6
      }}
    >
      {showIcon && <PictorialIcon icon={iconVariant} />}
      <Box
        sx={{
          height: "auto"
        }}
      >
        <Text as="p" size="lg" sx={{ fontWeight: "medium", my: 2 }}>
          {title}
        </Text>
        <Text
          as="p"
          sx={{
            color: "text02",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word"
          }}
        >
          {description}
          {children}
        </Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", pt: 4, mt: "auto" }}>
        <Button
          href={button.href}
          download={button.download}
          external={button.external}
          variant={button.variant}
        >
          {button.title}
        </Button>
      </Box>
    </Box>
  );
};

export default InfoCard;
