import {
  BoxSx,
  Carousel,
  CarouselItem,
  Grid,
  GridColumn,
  ThemeOverride
} from "@modernatx/ui-kit-react";
import React from "react";

import { EligibilityQuizBlockProps } from "@/types/Block";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../../BlockText";
import { Birthdate } from "./CarouselItems/Birthdate";
import { BmiCalculatorQuizCard } from "./CarouselItems/BmiCalculatorQuizCard";
import { MultipleChoice, MultipleChoiceProps } from "./CarouselItems/common/MultipleChoice";
import { CovidAndFluQuestions } from "./CarouselItems/CovidAndFluQuestions";
import { CovidQuestions } from "./CarouselItems/CovidQuestions";
import { FluQuestion } from "./CarouselItems/FluQuestion";
import { EligibilityProvider, useEligibility } from "./hooks/useEligibility";

export interface CheckboxCardProps {
  title: Exclude<BlockTextProps["text"], undefined>;
  checkboxItems: {
    label: string;
  }[];
}

export interface UKQuizProps {
  birthdateProps: {
    title: Exclude<BlockTextProps["text"], undefined>;
    inputLabel: string;
    buttonText: Exclude<BlockTextProps["text"], undefined>;
  };
  bmiQuestionProps: MultipleChoiceProps;
  bmiCalculatorProps: {
    weightInputTitle: Exclude<BlockTextProps["text"], undefined>;
    weightInputLabel: string;
    heightInputTitle: Exclude<BlockTextProps["text"], undefined>;
    heightInputLabel: string;
    submitButtonText: string;
  };
  fluQuestionProps: {
    title: Exclude<BlockTextProps["text"], undefined>;
  };
  covidAndFluQuestionsProps: CheckboxCardProps;
  covidQuestionsProps: CheckboxCardProps;
  eligibleForCovidAndFluProps: MultipleChoiceProps;
  eligibleForCovidProps: MultipleChoiceProps;
  eligibleForFluProps: MultipleChoiceProps;
  ineligibleProps: MultipleChoiceProps;
}
const CarouseItemStyles: BoxSx = {
  alignItems: "stretch",
  backgroundColor: "background01",
  gap: 5,
  p: 8,
  ml: -5,
  mr: -5
};

const UKQuizComponent: React.FC<UKQuizProps> = ({
  birthdateProps,
  bmiQuestionProps,
  bmiCalculatorProps,
  covidAndFluQuestionsProps,
  covidQuestionsProps,
  fluQuestionProps,
  eligibleForCovidAndFluProps,
  eligibleForCovidProps,
  eligibleForFluProps,
  ineligibleProps
}) => {
  const { resetKey } = useEligibility();

  return (
    <Carousel
      disableNavigation={{ next: true, previous: false }}
      hide={{ next: true, previous: true }}
    >
      <CarouselItem key={`birthdate-${resetKey}`} id="Birthdate" sx={CarouseItemStyles}>
        <Birthdate {...birthdateProps} />
      </CarouselItem>
      <CarouselItem key={`BmiQuestion-${resetKey}`} id="BmiQuestion" sx={CarouseItemStyles}>
        <MultipleChoice {...bmiQuestionProps} />
      </CarouselItem>
      <CarouselItem key={`BmiCalculator-${resetKey}`} id="BmiCalculator" sx={CarouseItemStyles}>
        <BmiCalculatorQuizCard {...bmiCalculatorProps} />
      </CarouselItem>
      <CarouselItem
        key={`CovidAndFluQuestions-${resetKey}`}
        id="CovidAndFluQuestions"
        sx={CarouseItemStyles}
      >
        <CovidAndFluQuestions {...covidAndFluQuestionsProps} />
      </CarouselItem>
      <CarouselItem key={`CovidQuestions-${resetKey}`} id="CovidQuestions" sx={CarouseItemStyles}>
        <CovidQuestions {...covidQuestionsProps} />
      </CarouselItem>
      <CarouselItem key={`FluQuestion-${resetKey}`} id="FluQuestion" sx={CarouseItemStyles}>
        <FluQuestion {...fluQuestionProps} />
      </CarouselItem>
      <CarouselItem
        key={`EligibleForCovidAndFlu-${resetKey}`}
        id="EligibleForCovidAndFlu"
        sx={CarouseItemStyles}
      >
        <MultipleChoice {...eligibleForCovidAndFluProps} />
      </CarouselItem>
      <CarouselItem
        key={`EligibleForCovid-${resetKey}`}
        id="EligibleForCovid"
        sx={CarouseItemStyles}
      >
        <MultipleChoice {...eligibleForCovidProps} />
      </CarouselItem>
      <CarouselItem key={`EligibleForFlu-${resetKey}`} id="EligibleForFlu" sx={CarouseItemStyles}>
        <MultipleChoice {...eligibleForFluProps} />
      </CarouselItem>
      <CarouselItem key={`Ineligible-${resetKey}`} id="Ineligible" sx={CarouseItemStyles}>
        <MultipleChoice {...ineligibleProps} />
      </CarouselItem>
    </Carousel>
  );
};
export const EligibilityQuiz: React.FC<EligibilityQuizBlockProps> = ({ text, quizProps }) => {
  return (
    <Grid sx={{ gap: [5, 0, 0] }}>
      <GridColumn size={[4, 4, 7]}>
        <BlockText text={text} />
      </GridColumn>
      <GridColumn size={[4, 4, 5]}>
        <ThemeOverride mode="dark">
          <EligibilityProvider>
            <UKQuizComponent {...quizProps} />
          </EligibilityProvider>
        </ThemeOverride>
      </GridColumn>
    </Grid>
  );
};
