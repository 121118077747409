import { Box, Button } from "@modernatx/ui-kit-react";

import { VideoProps } from "@/types/Block";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";

export const VideoDetails: React.FC<{
  video: VideoProps;
  downloadButtonText: BlockTextProps[];
  onDownload: () => void;
  isDownloading: boolean;
}> = ({ video, downloadButtonText, onDownload, isDownloading }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5 }}>
    <BlockText text={video.title} size="xl" weight="bold" />
    <BlockText text={video.length} weight="light" />
    <BlockText text={video.description} />
    <Box sx={{ display: "flex", flexDirection: "row", paddingTop: 5 }}>
      <Button download={true} variant="secondary" disabled={isDownloading} onClick={onDownload}>
        <BlockText text={downloadButtonText} />
      </Button>
    </Box>
  </Box>
);
