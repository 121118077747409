import { Box, Headline, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { ReplaceText } from "@/components/rsv/ReplaceText";

export const useTextReplacements = () => {
  return React.useMemo<React.ComponentProps<typeof ReplaceText>["replace"]>(
    () => ({
      "br-desktop": () => <Box as="br" sx={{ display: ["none", "none", "block"] }} />,
      "br-mobile": () => <Box as="br" sx={{ display: ["block", "none", "none"] }} />,
      "br-tablet": () => <Box as="br" sx={{ display: ["none", "block", "none"] }} />,
      "p-bold": (props) => <Text as="p" variant="bold" {...props} />,
      br: () => <Box as="br" />,
      h1: (props) => <Headline as="h2" level={1} sx={{ mb: 2 }} {...props} />,
      h2: (props) => <Headline level={2} sx={{ mb: 2 }} {...props} />,
      h3: (props) => <Headline level={3} sx={{ mb: 2 }} {...props} />,
      p: (props) => <Text as="p" {...props} />,
      strong: (props) => <Text as="span" variant="bold" {...props} />
    }),
    []
  );
};
