import { Box } from "@modernatx/ui-kit-react";

export const VideoPlayer: React.FC<{ url: string; poster: string }> = ({ url, poster }) => (
  <Box sx={{ width: "100%", height: "auto" }}>
    <video
      controls={true}
      preload="auto"
      src={url}
      poster={poster}
      style={{ width: "100%", height: "auto" }}
    />
  </Box>
);
