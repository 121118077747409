import React from "react";

import { config } from "@/config/environment";

declare const grecaptcha: {
  enterprise: {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
  };
};

interface UseEmailSignUpConfig {
  apiEndpoint: string;
  consentCommercial?: boolean;
  country?: string;
  mResviaProInfo?: boolean;
}

export enum EmailSignupError {
  InvalidEmail,
  ServerError
}

const isValidEmail = (email: string) => {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegex.test(email);
};

export function useEmailSignUp({
  apiEndpoint,
  consentCommercial,
  country,
  mResviaProInfo
}: UseEmailSignUpConfig) {
  const [erroring, setErroring] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<EmailSignupError | null>(null);
  const [success, setSuccess] = React.useState(false);
  const [value, setValue] = React.useState("");

  const submitEmail = React.useCallback(async () => {
    setError(null);
    setErroring(true);
    setSubmitting(true);
    setSuccess(false);

    if (!isValidEmail(value)) {
      setError(EmailSignupError.InvalidEmail);
      setSubmitting(false);
      return false;
    }

    try {
      const token = await grecaptcha.enterprise.execute(`${config.recaptchaSiteKey}`, {
        action: "submit"
      });

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: value,
          consentCommercial,
          country,
          mResviaProInfo,
          recaptchaToken: token
        })
      });

      if (response.ok) {
        setSuccess(true);
        setError(null);
        setErroring(false);
        setValue("");
      } else {
        setError(EmailSignupError.ServerError);
      }
    } catch (e) {
      setError(EmailSignupError.ServerError);
    } finally {
      setSubmitting(false);
    }
  }, [apiEndpoint, consentCommercial, country, mResviaProInfo, value]);

  return React.useMemo(
    () => ({
      error,
      erroring,
      submitting,
      success,
      setValue: (valueNext: string) => {
        setErroring(true);
        setValue(valueNext);
      },
      submitEmail,
      value
    }),
    [submitting, error, erroring, success, submitEmail, value]
  );
}
