import { ModernaTheme } from "@modernatx/ui-kit-react";
import React from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false); // Default to false for SSR

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= parseInt(ModernaTheme.breakpoints[1], 10));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};
