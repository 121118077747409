import { useOverlay } from "@modernatx/ui-kit-react";
import React from "react";

import { Expanded } from "@/components/blocks/ISIPanel";

const useBodyScrollLock = (isLocked: Expanded, panelRef: React.RefObject<HTMLDivElement>) => {
  const { mountOverlay, unmountOverlay } = useOverlay();
  const overlayId = React.useRef(0);

  React.useEffect(() => {
    const handleScrollLock = () => {
      if (panelRef.current) {
        const panelHeight = panelRef.current.offsetHeight;
        const windowHeight = window.innerHeight;

        if (isLocked === true) {
          if (panelHeight >= windowHeight && !overlayId.current) {
            overlayId.current = mountOverlay();
          } else if (panelHeight < windowHeight && overlayId.current) {
            unmountOverlay(overlayId.current);
            // Remove the overlay id so we know the overlay is no
            // longer mounted
            overlayId.current = 0;
          }
        }
      }
    };

    handleScrollLock();
    window.addEventListener("resize", handleScrollLock);

    return () => {
      window.removeEventListener("resize", handleScrollLock);
    };
  }, [isLocked, mountOverlay, panelRef, unmountOverlay]);

  React.useEffect(() => {
    if (!isLocked && overlayId.current) {
      unmountOverlay(overlayId.current);
      // Remove the overlay id so we know the overlay is no
      // longer mounted
      overlayId.current = 0;
    }
  }, [isLocked, unmountOverlay]);

  React.useEffect(
    () => () => {
      // Unmount the overlay from the stack if the component
      // is unmounted from the DOM
      if (overlayId.current) {
        unmountOverlay(overlayId.current);
      }
    },
    [unmountOverlay]
  );
};

export default useBodyScrollLock;
