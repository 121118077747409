import { useCarouselContext } from "@modernatx/ui-kit-react";
import React from "react";

import { UKQuizProps } from "../EligibilityQuiz";
import { useCheckboxes } from "../hooks/useCheckboxes";
import { CheckboxCard } from "./common/CheckboxCard";

export const CovidAndFluQuestions: React.FC<UKQuizProps["covidAndFluQuestionsProps"]> = (props) => {
  const { goToSlideId } = useCarouselContext();
  const { numberOfCheckboxesSelected, checkboxStates, handleCheckboxChange } = useCheckboxes(
    props.checkboxItems
  );

  const handleClick = React.useCallback(() => {
    if (numberOfCheckboxesSelected > 0) {
      goToSlideId("EligibleForCovidAndFlu");
    } else {
      goToSlideId("CovidQuestions");
    }
  }, [numberOfCheckboxesSelected, goToSlideId]);

  const checkboxCardProps = React.useMemo(() => {
    return {
      checkboxStates,
      handleCheckboxChange,
      handleClick,
      ...props
    };
  }, [checkboxStates, handleCheckboxChange, handleClick, props]);
  return <CheckboxCard {...checkboxCardProps} />;
};
