import { Box } from "@modernatx/ui-kit-react";
import React from "react";

interface NavIntersectorProps {
  children?: React.ReactNode;
  index: number;
  onIntersecting: (intersecting: boolean) => void;
}

export const NavIntersector: React.FC<NavIntersectorProps> = ({
  children,
  index,
  onIntersecting
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const currentElement = ref.current;
    let observer: IntersectionObserver | null = null;

    if (currentElement) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries.length > 0) {
            onIntersecting(!!entries[0]?.isIntersecting);
          }
        },
        {
          root: null,
          rootMargin: "-80px",
          threshold: 0
        }
      );

      observer.observe(currentElement);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [onIntersecting]);

  return (
    <Box ref={ref} data-scroll-element={index}>
      {children}
    </Box>
  );
};
