import { Box, Button, DropdownOption, Input, ThemeOverride } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useLocationSearch } from "@/finder/hooks/useLocationSearch";
import { LocationFinderProps } from "@/types/Block";

export const LocationFinder: React.FC<LocationFinderProps> = ({
  button,
  finderLocation = "/finder",
  input,
  layout = "horizontal",
  mode = "light",
  product = ""
}) => {
  const [searchValue, searchValueSet] = React.useState(input.value);
  const router = useRouter();

  const searchOptionSelect = React.useCallback(
    (option: DropdownOption) => {
      if (option) {
        const queryParams = new URLSearchParams({ placeId: String(option.value) });

        if (product) {
          queryParams.append("products", product);
        }

        router.push(`${finderLocation}?${queryParams.toString()}`);
      }
    },
    [finderLocation, router, product]
  );

  const {
    searchSuggestionsList,
    showNoResults,
    handleSearchChange,
    handleSearchSubmit,
    handleSearchSuggestionClick
  } = useLocationSearch({
    searchValue,
    searchValueSet,
    searchOptionSelect
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchSubmit();
  };

  return (
    <>
      <ThemeOverride mode={mode}>
        <Box
          as="form"
          sx={{
            display: "flex",
            flexDirection: ["column", "column", layout === "vertical" ? "column" : "row"],
            gap: 2,
            position: "relative"
          }}
          onSubmit={onSubmit}
        >
          <Input
            dropdownOptions={searchSuggestionsList}
            label={input.label}
            value={searchValue}
            type="select"
            sx={{ flex: 1 }}
            showNoResults={showNoResults}
            onChange={handleSearchChange}
            onSelectOption={handleSearchSuggestionClick}
            onEnter={handleSearchSubmit}
          />
          <Button variant={button.variant} type="submit">
            {button.title}
          </Button>
        </Box>
      </ThemeOverride>
    </>
  );
};
