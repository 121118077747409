import React from "react";
import type { Descendant } from "slate";

import { useTextBlockRendering } from "./TextBlockRenderContext";

interface TextBlockProps {
  id: string;
  initialValue: Descendant[];
}

export const TextBlock: React.FC<TextBlockProps> = ({ id, initialValue }) => {
  const { renderTextBlock } = useTextBlockRendering();
  return renderTextBlock(id, initialValue);
};
