import { Icon, Target, ThemeProvided } from "@modernatx/ui-kit-react";
import React from "react";

type NavArrowProps = React.ComponentProps<typeof Target> & {
  direction: "end" | "start";
};

export const NavArrow: React.FC<NavArrowProps> = ({ direction, sx, ...props }) => {
  return (
    <Target
      {...props}
      sx={{
        ...sx,
        alignItems: "center",
        display: ["flex", "flex", "none"],
        left: direction === "start" ? 0 : "unset",
        paddingInlineStart: 1,
        position: "absolute",
        py: 6,
        right: direction === "end" ? 0 : "unset",
        transform: direction === "end" ? "rotate(180deg)" : "none",
        zIndex: 2,
        "::before": {
          background: (theme: ThemeProvided) =>
            `linear-gradient(90deg, ${theme.colors?.backgroundNeutral01} 65.28%, rgba(255, 255, 255, 0) 100%)`,
          bottom: 0,
          content: '""',
          left: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          width: 8,
          zIndex: 0
        }
      }}
    >
      <Icon icon="caret-left" />
    </Target>
  );
};
